import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/common/Button'
import ImagePreview from 'components/ImagePreview'
import { OutputFormat } from 'types/OutputFormat'
import IconButton from 'components/common/IconButton'
import { ReactComponent as ArrowBackIcon } from '@material-design-icons/svg/round/arrow_back_ios.svg'
import c from './PreviewStage.module.scss'

interface PreviewStageProps {
  imageUrl: string
  outputFormat: OutputFormat
  onOutputFormatChange: (newOutputFormat: OutputFormat) => void
  onProcessingStart: () => void
  onGoBack: () => void
}

function PreviewStage({
  imageUrl,
  outputFormat,
  onOutputFormatChange,
  onProcessingStart,
  onGoBack,
}: PreviewStageProps) {
  const { t } = useTranslation()

  const handleOutputFormatChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onOutputFormatChange(e.target.value as OutputFormat)
    },
    [onOutputFormatChange],
  )

  return (
    <div className={c.previewStage}>
      <div className={c.container}>
        <div className={c.navigationPanel}>
          <IconButton onClick={onGoBack} className={c.goBackButton}>
            <ArrowBackIcon style={{ fill: 'currentColor' }} />
          </IconButton>
        </div>
        <div className={c.content}>
          <div className={c.imageWrapper}>
            <img src={imageUrl} alt="" className={c.image} />
          </div>
          <div className={c.controlPanel}>
            <h5 className={c.controlPanelTitle}>
              {t('stages.enhancement.preview.controlPanelTitle')}
            </h5>
            <div className={c.switch}>
              <label className={c.switchButton}>
                <input
                  className={`sr-only ${c.switchInput}`}
                  type="radio"
                  name="outputFormat"
                  value="jpeg"
                  checked={outputFormat === 'jpeg'}
                  onChange={handleOutputFormatChange}
                />
                <span className={c.switchBody}>
                  <span className={c.switchHeading}>JPEG</span>
                  <span className={c.switchText}>
                    {t('stages.enhancement.preview.jpegText')}
                  </span>
                  <span className={c.switchFiller} />
                </span>
              </label>
              <label className={c.switchButton}>
                <input
                  className={`sr-only ${c.switchInput}`}
                  type="radio"
                  name="outputFormat"
                  value="png"
                  checked={outputFormat === 'png'}
                  onChange={handleOutputFormatChange}
                />
                <span className={c.switchBody}>
                  <span className={c.switchHeading}>PNG</span>
                  <span className={c.switchText}>
                    {t('stages.enhancement.preview.pngText')}
                  </span>
                  <span className={c.switchFiller} />
                </span>
              </label>
            </div>
            <Button className={c.button} onClick={onProcessingStart}>
              {t('stages.enhancement.preview.buttonTextProcessing')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreviewStage
