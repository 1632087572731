import React, { useCallback, useMemo } from 'react'
import { ReactComponent as CloseIcon } from '@material-design-icons/svg/round/close.svg'
import { AnimatePresence, motion } from 'framer-motion'
import IconButton from 'components/common/IconButton'
import c from './Modal.module.scss'

const handleInnerClick = (e: React.MouseEvent<HTMLDivElement>) => {
  e.stopPropagation()
}

interface ModalProps {
  open: boolean
  heading: string
  children: React.ReactNode
  onClose?: () => void
}

function Modal({ open, heading, children, onClose = undefined }: ModalProps) {
  const classNames = useMemo(() => {
    let result = c.wrapper
    if (open) result += ` ${c.wrapperOpen}`
    return result
  }, [open])

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key !== 'Escape') return
      if (onClose) onClose()
    },
    [onClose],
  )

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          role="button"
          tabIndex={0}
          className={classNames}
          onClick={onClose}
          onKeyDown={handleKeyPress}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className={c.modalContainer}>
            <div
              role="button"
              aria-hidden="true"
              className={c.modal}
              onClick={handleInnerClick}
            >
              <div className={c.content}>
                <div className={c.head}>
                  <h6 className={c.heading}>{heading}</h6>
                  {onClose && (
                    <IconButton onClick={onClose}>
                      <CloseIcon style={{ fill: 'currentColor' }} />
                    </IconButton>
                  )}
                </div>
                <div>{children}</div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Modal
