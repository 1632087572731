import React, { useEffect, useMemo, useState } from 'react'

import Handlers from 'types/Handlers'
import { useTranslation } from 'react-i18next'
import useLocationInfo from 'hooks/useLocationInfo'
import { NavLink } from 'react-router-dom'
import Tabs from 'components/Tabs'
import Generation from 'components/Generation'
import MagicVariations from 'components/MagicVariations'
import VideoArt from 'components/VideoArt/VideoArt'
import { useAppSelector } from 'hooks/store'
// import { changeStage } from 'store/reducers/stageReducer'
import { useDispatch } from 'react-redux'
import { changeStage } from 'store/reducers/stageReducer'

import c from './GenerationPage.module.scss'

function GenerationPage({ handler }: { handler: `${Handlers}` }) {
  const { stage } = useAppSelector((state) => state.stage)

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { pathname } = useLocationInfo()
  const [handlerState, setHandlerState] = useState(handler)

  useEffect(() => {
    dispatch(changeStage('idle'))
    setHandlerState(handler)
  }, [handler])

  const indexOfCurrentPage = useMemo(() => {
    if (pathname.includes('/neuro-art')) {
      return 0
    }
    if (pathname.includes('/magic-variations')) {
      return 1
    }
    if (pathname.includes('/video-art')) {
      return 2
    }
    return 0
  }, [pathname])

  return (
    <>
      {stage === 'idle' && (
        <div className={c.topPage}>
          <div className={c.tabsWrapper}>
            <Tabs indexOfCurrentPage={indexOfCurrentPage} className={c.tabs}>
              <div className={c.tabLink}>
                {t('navigation.generationSpace')}
                <NavLink className={c.linkFill} to="/neuro-art" end>
                  {t('navigation.generationSpace')}
                </NavLink>
              </div>
              <div className={c.tabLink}>
                {t('navigation.magicVariations')}
                <NavLink className={c.linkFill} to="/magic-variations">
                  {t('navigation.magicVariations')}
                </NavLink>
              </div>
              <div className={c.tabLink}>
                {t('navigation.videoArtSpace')}
                <NavLink className={c.linkFill} to="/video-art">
                  {t('navigation.videoArtSpace')}
                </NavLink>
              </div>
            </Tabs>
          </div>
        </div>
      )}

      {handlerState === 'George_Washington' && <Generation />}
      {handlerState === 'Thomas_Jefferson' && <MagicVariations />}
      {handlerState === 'James_Monroe' && <VideoArt />}
    </>
  )
}

export default GenerationPage
