import React, { useMemo } from 'react'
import c from './TextInput.module.scss'

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
}

function TextInput({
  className,
  startIcon = undefined,
  endIcon = undefined,
  ...props
}: TextInputProps) {
  const classNames = useMemo(() => {
    let result = c.inputContainer
    if (startIcon) result += ` ${c.inputContainerLeftPadded}`
    if (endIcon) result += ` ${c.inputContainerRightPadded}`
    if (className) result += ` ${className}`
    return result
  }, [className, startIcon, endIcon])

  return (
    <div className={classNames}>
      {startIcon}
      <input className={c.input} {...props} />
      {endIcon}
    </div>
  )
}

export default TextInput
