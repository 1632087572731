import React from 'react'
import { useTranslation } from 'react-i18next'
import Link from 'components/common/Link'
import Modal from 'components/common/Modal'
import c from './PromoModal.module.scss'

interface PromoModalProps {
  open: boolean
  onClose: () => void
}

function PromoModal({ open, onClose }: PromoModalProps) {
  const { t } = useTranslation()

  return (
    <Modal open={open} heading={t('modals.promo.heading')} onClose={onClose}>
      <p className={c.text}>{t('modals.promo.text')}</p>
      <Link className={c.button} to="/neuro-art" onClick={onClose}>
        {t('modals.promo.button')}
      </Link>
    </Modal>
  )
}

export default PromoModal
