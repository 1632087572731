import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import MainLayout from 'layouts/MainLayout'
import EnhancementPage from 'pages/EnhancementPage'
import SettingsPage from 'pages/SettingsPage'
import DocumentPage from 'pages/DocumentPage'
import WebviewPage from 'pages/WebviewPage'
import HistoryPage from 'pages/HistoryPage'
import ProfilePage from 'pages/ProfilePage'
import NoMatchPage from 'pages/NoMatchPage'
import SettingsList from 'components/SettingsList'
import LanguageChange from 'components/LanguageChange'
import TermsOfUse from 'components/documents/TermsOfUse'
import PrivacyPolicy from 'components/documents/PrivacyPolicy'
import FeedPage from 'pages/FeedPage'
import PostPage from 'pages/PostPage'
import TrendingPage from 'pages/TrendingPage'
import MagicVariations from 'components/MagicVariations'
import EnhancementFinishPage from 'pages/EnhancementFinishPage'
import GenerationFinishPage from 'pages/GenerationFinishPage'
import VariationFinishPage from 'pages/VariationFinishPage'
import VideoArtFinishPage from 'pages/VideoArtFinishPage'
import TrendingVideoPage from 'pages/TrendingVideoPage'
import GenerationPage from 'pages/GenerationPage'

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<MainLayout />}>
          <Route index element={<FeedPage />} />
          <Route
            path="enhancement"
            element={<EnhancementPage handler="George_Washington" />}
          />
          <Route
            path="enhancement/finish/:assetId"
            element={<EnhancementFinishPage />}
          />
          <Route
            path="face-restoration"
            element={<EnhancementPage handler="Thomas_Jefferson" />}
          />
          <Route
            path="background-remover"
            element={<EnhancementPage handler="James_Monroe" />}
          />
          <Route
            path="neuro-art"
            element={<GenerationPage handler="George_Washington" />}
          />
          <Route
            path="generation/finish/:requestId"
            element={<GenerationFinishPage />}
          />
          <Route
            path="magic-variations"
            element={<GenerationPage handler="Thomas_Jefferson" />}
          />
          <Route
            path="magic-variations/:assetId"
            element={<GenerationPage handler="Thomas_Jefferson" />}
          />
          <Route
            path="magic-variations/finish/:requestId"
            element={<VariationFinishPage />}
          />
          <Route
            path="video-art/finish/:requestId"
            element={<VideoArtFinishPage />}
          />
          <Route
            path="video-art"
            element={<GenerationPage handler="James_Monroe" />}
          />
          <Route path="feed" element={<FeedPage />} />
          <Route path="trending" element={<TrendingPage />} />
          <Route path="trending-video-art" element={<TrendingVideoPage />} />
          <Route path="settings" element={<SettingsPage />}>
            <Route index element={<SettingsList />} />
            <Route path="language" element={<LanguageChange />} />
          </Route>
          <Route path="post/:id" element={<PostPage />} />
          <Route path="profile/:id" element={<ProfilePage />} />
          <Route path="history/:requestId" element={<HistoryPage />} />
          <Route path="/*" element={<SettingsPage />}>
            <Route
              path="terms-of-use"
              element={<DocumentPage document={<TermsOfUse />} />}
            />
            <Route
              path="privacy-policy"
              element={<DocumentPage document={<PrivacyPolicy />} />}
            />
          </Route>
          <Route path="*" element={<NoMatchPage />} />
        </Route>
        <Route path="/webview" element={<WebviewPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
