import React, { useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { ReactComponent as ChevronRightIcon } from '@material-design-icons/svg/round/chevron_right.svg'
import { ReactComponent as ShareIcon } from '@material-design-icons/svg/round/share.svg'

import InstagramIcon from 'components/icons/InstagramIcon'

import {
  setLogInModalOpen,
  setSignUpModalOpen,
} from 'store/reducers/modalReducer'
import { useAppSelector } from 'hooks/store'
import useModal from 'hooks/useModal'
import useLogOut from 'hooks/query/auth/useLogOut'

import c from './SettingsList.module.scss'

const currentYear = new Date().getFullYear()

function SettingsList() {
  const { t } = useTranslation()
  const { logInModalOpen, signUpModalOpen } = useAppSelector(
    (state) => state.modal,
  )

  const { open: openLogInModal } = useModal(logInModalOpen, setLogInModalOpen)
  const { open: openSignUpModal } = useModal(
    signUpModalOpen,
    setSignUpModalOpen,
  )

  const user = useAppSelector((state) => state.auth.user)

  const { mutate: logOut, error } = useLogOut()

  const handleLogOut = useCallback(() => {
    logOut()
  }, [logOut])

  const handleShare = useCallback(() => {
    const url = window.location.origin
    const data = { url }
    const canShare = navigator.canShare(data)
    if (canShare) {
      navigator.share(data)
      return
    }
    toast.error(t('error.browser'))
  }, [t])

  useEffect(() => {
    if (error?.message) toast.error(error.message)
  }, [error?.message])

  return (
    <div className={c.settings}>
      <div className={c.settingsItem}>
        <p className={c.listTitle}>{t('settings.profile.title')}</p>
        <ul className={c.list}>
          {user ? (
            <>
              <li className={c.listItem}>
                <p className={c.text}>{user.email}</p>
              </li>
              <li className={c.listItem}>
                <button
                  className={c.button}
                  type="button"
                  onClick={handleLogOut}
                >
                  <span className={c.buttonText}>
                    {t('settings.profile.items.logOut')}
                  </span>
                  <ChevronRightIcon className={c.buttonEndIcon} />
                </button>
              </li>
            </>
          ) : (
            <>
              <li className={c.listItem}>
                <button
                  className={c.button}
                  type="button"
                  onClick={openLogInModal}
                >
                  <span className={c.buttonText}>
                    {t('settings.profile.items.logIn')}
                  </span>
                  <ChevronRightIcon className={c.buttonEndIcon} />
                </button>
              </li>
              <li className={c.listItem}>
                <button
                  className={c.button}
                  type="button"
                  onClick={openSignUpModal}
                >
                  <span className={c.buttonText}>
                    {t('settings.profile.items.signUp')}
                  </span>
                  <ChevronRightIcon className={c.buttonEndIcon} />
                </button>
              </li>
            </>
          )}
        </ul>
      </div>

      <div className={c.settingsItem}>
        <p className={c.listTitle}>{t('settings.general.title')}</p>
        <ul className={c.list}>
          <li className={c.listItem}>
            <Link className={c.button} to="/settings/language">
              <span className={c.buttonText}>
                {t('settings.general.items.language')}
              </span>
              <ChevronRightIcon className={c.buttonEndIcon} />
            </Link>
          </li>
        </ul>
      </div>

      <div className={c.settingsItem}>
        <p className={c.listTitle}>{t('settings.social.title')}</p>
        <ul className={c.list}>
          <li className={c.listItem}>
            <button className={c.button} type="button" onClick={handleShare}>
              <ShareIcon className={c.buttonStartIcon} />
              <span className={c.buttonText}>
                {t('settings.social.items.share')}
              </span>
            </button>
          </li>
          <li className={c.listItem}>
            <a
              className={c.button}
              href="https://instagram.com/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon className={c.buttonStartIcon} />
              <span className={c.buttonText}>
                {t('settings.social.items.instagram')}
              </span>
            </a>
          </li>
        </ul>
      </div>

      <div className={c.settingsItem}>
        <p className={c.listTitle}>{t('settings.documents.title')}</p>
        <ul className={c.list}>
          <li className={c.listItem}>
            <Link className={c.button} to="/terms-of-use">
              <span className={c.buttonText}>
                {t('settings.documents.items.terms')}
              </span>
              <ChevronRightIcon className={c.buttonEndIcon} />
            </Link>
          </li>
          <li className={c.listItem}>
            <Link className={c.button} to="/privacy-policy">
              <span className={c.buttonText}>
                {t('settings.documents.items.policy')}
              </span>
              <ChevronRightIcon className={c.buttonEndIcon} />
            </Link>
          </li>
        </ul>
      </div>

      <p className={c.copyright}>&copy; 2020-{currentYear} RetoMagic</p>
    </div>
  )
}

export default SettingsList
