import React from 'react'

import Container from 'components/common/Container'
import GenerationInput from 'components/GenerationInput'
import Suggestions from 'components/Suggestions'
import GenerationStyles from 'components/GenerationStyles'
import GenerationFixedButton from 'components/GenerationFixedButton'
import AdvancedSettings from 'components/AdvancedSettings'

import { Suggestion, Style } from 'types/Generation'

import c from './IdleStage.module.scss'

interface IdleStageProps {
  text: string
  suggestions?: Suggestion[]
  styles?: Style[]
  chosenStyle: string | undefined
  onTextChange: (newText: string) => void
  onProcessingStart: () => void
  onExampleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  onStyleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  resolution: number
  setResolution: (newIndex: any) => void
  guidanceScale: number
  setGuidanceScale: (newIndex: number) => void
  seed: string | undefined
  setSeed: (newSeed: string) => void
  steps: number
  setSteps: (newStep: number) => void
  negativePrompt: string
  setNegativePrompt: (newPrompt: string) => void
  isAdvancedSettingsOpen: boolean
  setIsAdvancedSettingsOpen: (prev: boolean) => void
}

function IdleStage({
  text,
  suggestions = [],
  styles = [],
  chosenStyle,
  onTextChange,
  onProcessingStart,
  onExampleClick,
  onStyleClick,
  resolution,
  setResolution,
  guidanceScale,
  setGuidanceScale,
  seed,
  setSeed,
  steps,
  setSteps,
  negativePrompt,
  setNegativePrompt,
  isAdvancedSettingsOpen,
  setIsAdvancedSettingsOpen,
}: IdleStageProps) {
  return (
    <Container flex>
      <div className={c.content}>
        <GenerationInput
          text={text}
          onTextChange={onTextChange}
          onProcessingStart={onProcessingStart}
        />
        <Suggestions
          suggestions={suggestions}
          onExampleClick={onExampleClick}
        />
        <AdvancedSettings
          isAdvancedSettingsOpen={isAdvancedSettingsOpen}
          setIsAdvancedSettingsOpen={setIsAdvancedSettingsOpen}
          negativePrompt={negativePrompt}
          setNegativePrompt={setNegativePrompt}
          resolution={resolution}
          setResolution={setResolution}
          guidanceScale={guidanceScale}
          setGuidanceScale={setGuidanceScale}
          seed={seed}
          setSeed={setSeed}
          steps={steps}
          setSteps={setSteps}
        />
        <GenerationStyles
          chosenStyle={chosenStyle}
          styles={styles}
          onStyleClick={onStyleClick}
        />
      </div>
      <GenerationFixedButton
        text={text}
        onProcessingStart={onProcessingStart}
      />
    </Container>
  )
}

export default IdleStage
