import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Processor, { VideoStrategy } from 'api/Retomagic'
import {
  changeCurrentAssetIndex,
  changeProcessedAssets,
  changeStage,
} from 'store/reducers/stageReducer'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import useFetchStyles from 'hooks/query/useFetchStyles'
import useFetchSuggestions from 'hooks/query/useFetchSuggestions'

import Container from 'components/common/Container'
import IdleStage from 'components/stages/video/IdleStage'
import ProcessingStage from 'components/stages/video/ProcessingStage'

import {
  ProcessedAssetsChangedPayload,
  ProcessorEvents,
} from 'types/ProcessorEvents'

import c from './VideoArt.module.scss'

function VideoArt() {
  const dispatch = useAppDispatch()

  const { stage, processedAssets, currentAssetIndex } = useAppSelector(
    (state) => state.stage,
  )

  const navigate = useNavigate()

  const [generationProcessor, setGenerationProcessor] =
    useState<Processor | null>(null)

  const { data: stylesData } = useFetchStyles()

  const { data: suggestionsData } = useFetchSuggestions()

  const [chosenStyle, setChosenStyle] = useState<string | undefined>('')

  const [text, setText] = useState<string>('')

  const handleProcessedAssetsChanged = useCallback(
    (payload: ProcessedAssetsChangedPayload) => {
      const { assets, isFirstSucceededResult, id } = payload

      dispatch(changeProcessedAssets(assets))
      dispatch(changeStage('idle'))

      if (isFirstSucceededResult) {
        navigate(`/video-art/finish/${id}`)
      }
    },
    [],
  )

  const handleProcessingStart = useCallback(async () => {
    dispatch(changeStage('processing'))

    const thisStyle = stylesData.find((elem) => elem.id === chosenStyle)

    const strategy = new VideoStrategy(text)

    const processor = new Processor(strategy)
    processor.on(
      ProcessorEvents.PROCESSED_ASSETS_CHANGED,
      handleProcessedAssetsChanged,
    )

    await processor.start()

    setGenerationProcessor(processor)
  }, [text, handleProcessedAssetsChanged, chosenStyle, stylesData])

  useEffect(() => {
    // Сброс данных, если пользователь вернулся на главный экран
    if (stage !== 'idle') return
    setText('')
    dispatch(changeProcessedAssets([]))
    dispatch(changeCurrentAssetIndex(0))
  }, [stage])

  useEffect(() => {
    // Изменение допустимых touch-action
    document.documentElement.style.setProperty('--touch-action', 'none')

    return () => {
      document.documentElement.style.setProperty('--touch-action', 'pan-y')
    }
  }, [])

  const onExampleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setText(event.currentTarget?.value)
    },
    [],
  )

  const onStyleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (chosenStyle === event.currentTarget.value) {
        setChosenStyle(undefined)
      } else {
        setChosenStyle(event.currentTarget.value)
      }
    },
    [chosenStyle],
  )

  useEffect(() => {
    return () => {
      if (stage === 'processing') {
        dispatch(changeProcessedAssets([]))
        dispatch(changeCurrentAssetIndex(0))
        setText('')
        generationProcessor?.stop()
        setGenerationProcessor(null)
        dispatch(changeStage('idle'))
      }
    }
  }, [generationProcessor])

  return (
    <div className={c.main}>
      {stage === 'idle' && (
        <IdleStage
          text={text}
          onTextChange={setText}
          onProcessingStart={handleProcessingStart}
          onExampleClick={onExampleClick}
          onStyleClick={onStyleClick}
          chosenStyle={chosenStyle}
          suggestions={suggestionsData}
          styles={stylesData}
        />
      )}
      {stage === 'processing' && (
        <Container flex>
          <ProcessingStage />
        </Container>
      )}
    </div>
  )
}

export default VideoArt
