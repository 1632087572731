import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { fetchImage } from 'api/methods'

const useShare = (imageUrl: string | undefined) => {
  const { t } = useTranslation()

  const handleShare = useCallback(async () => {
    if (imageUrl == null) return

    let imageFile
    try {
      imageFile = await fetchImage(imageUrl) // TODO: кешировать скачанный файл
    } catch (e) {
      toast.error(t('error.download'))
      return
    }

    const data = { files: [imageFile] }
    const canShare = navigator.canShare(data)
    if (canShare) {
      navigator.share(data).then().catch()
    } else {
      toast.error(t('error.browser'))
    }
  }, [t, imageUrl])

  return { handleShare }
}

export default useShare
