import React from 'react'
import { useTranslation } from 'react-i18next'
import CircleLoader from 'components/common/CircleLoader'
import c from './ProcessingStage.module.scss'

function ProcessingStage() {
  const { t } = useTranslation()

  return (
    <div className={c.processingStage}>
      <div className={c.loaderWrapper}>
        <CircleLoader
          loadingText={`${t(
            'stages.enhancement.processing.loaderTextUpgrading',
          )}...`}
        />
      </div>
      <p className={c.bannerText}>
        {`${t('stages.enhancement.processing.bannerText')}...`}
      </p>
    </div>
  )
}

export default ProcessingStage
