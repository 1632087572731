import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'hooks/store'
import useFetchRequest from 'hooks/query/useFetchRequest'
import {
  changeCurrentAssetIndex,
  changeProcessedAssets,
  changeStage,
} from 'store/reducers/stageReducer'
import Processor, { VariationsStrategy } from 'api/Retomagic'
import {
  ProcessedAssetsChangedPayload,
  ProcessorEvents,
} from 'types/ProcessorEvents'

import FinishStage from 'components/stages/generation/FinishStage'
import ProcessingStage from 'components/stages/generation/ProcessingStage'
import Container from 'components/common/Container'

import c from './VariationFinishPage.module.scss'

function VariationFinishPage() {
  const dispatch = useAppDispatch()
  const { stage, processedAssets, currentAssetIndex } = useAppSelector(
    (state) => state.stage,
  )

  const [text, setText] = useState<string | undefined>('')
  const [sharedSlides, setSharedSlides] = useState<number[]>([])

  const navigate = useNavigate()

  const [generationProcessor, setGenerationProcessor] =
    useState<Processor | null>(null)

  const { requestId } = useParams()
  const { data } = useFetchRequest({ requestId })

  useEffect(() => {
    if (sharedSlides.length > 0) setSharedSlides([])
  }, [requestId])

  const handleProcessedAssetsChanged = useCallback(
    (payload: ProcessedAssetsChangedPayload) => {
      const { assets, isFirstSucceededResult, id } = payload
      dispatch(changeProcessedAssets(assets))

      if (isFirstSucceededResult) {
        dispatch(changeStage('idle'))
        navigate(`/magic-variations/finish/${id}`)
      }
    },
    [],
  )

  const handleProcessingStart = useCallback(async () => {
    if (!text) return
    dispatch(changeStage('processing'))

    const strategy = new VariationsStrategy({
      image: '',
      request: text,
      strength: '',
      styleText: '',
      styleTitle: '',
      requestId,
    })

    const processor = new Processor(strategy)

    processor.on(
      ProcessorEvents.PROCESSED_ASSETS_CHANGED,
      handleProcessedAssetsChanged,
    )

    await processor.repeat()
    setGenerationProcessor(processor)
  }, [text, handleProcessedAssetsChanged, data])

  const handleGoBack = useCallback(() => {
    dispatch(changeStage('idle'))
    dispatch(changeProcessedAssets([]))
    dispatch(changeCurrentAssetIndex(0))
    setText('')
    navigate('/magic-variations')
  }, [])

  useEffect(() => {
    if (data) setText(data?.assets[0]?.source)
  }, [data])

  const currentAssetUrl = useMemo(() => {
    const currentAsset = processedAssets[currentAssetIndex]
    return currentAsset?.url
  }, [processedAssets, currentAssetIndex])

  useEffect(() => {
    return () => {
      if (stage === 'processing') {
        generationProcessor?.stop()
      }
    }
  }, [generationProcessor])

  return (
    <div className={c.variationFinishPage}>
      {stage === 'idle' && (
        <div className={c.container}>
          <FinishStage
            text={text}
            currentAssetUrl={currentAssetUrl}
            currentAssetIndex={currentAssetIndex}
            assets={data?.assets}
            sharedSlides={sharedSlides}
            setSharedSlides={setSharedSlides}
            onTextChange={setText}
            // onCurrentAssetIndexChange={setCurrentAssetIndex}
            onGenerateMore={handleProcessingStart}
            onGoBack={handleGoBack}
          />
        </div>
      )}
      {stage === 'processing' && (
        <Container flex>
          <ProcessingStage />
        </Container>
      )}
    </div>
  )
}

export default VariationFinishPage
