import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useFetchRequestProfile from 'hooks/query/useFetchRequestProfile'
import { useInView } from 'react-intersection-observer'
import { NavLink, useParams } from 'react-router-dom'
import { followRequest, unFollowRequest } from 'api'
import Container from 'components/common/Container'
import { ReactComponent as SettingIcon } from '@material-design-icons/svg/filled/settings.svg'
import { useMutation } from '@tanstack/react-query'
import Loader from 'components/common/Loader'
import useInfiniteFetchProfilePosts from 'hooks/query/useInfiniteFetchProfilePosts'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import c from './ProfilePage.module.scss'

function ProfilePage() {
  const { t } = useTranslation()

  const { id } = useParams()
  const {
    isLoading: profileIsLoading,
    error,
    data: myProfileData,
  } = useFetchRequestMyProfile()

  const isMyProfile = useMemo(
    () => id?.toString() === myProfileData?.id?.toString(),
    [id, myProfileData?.id],
  )

  const { data: userData } = useFetchRequestProfile({ id })

  const { ref, inView, entry } = useInView()

  const {
    data: infinitePostsData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteFetchProfilePosts({ id, take: 8 })

  const postsData = useMemo(() => {
    if (infinitePostsData === undefined) return []
    return infinitePostsData.pages.flatMap((p) => p)
  }, [infinitePostsData])

  useEffect(() => {
    if (isLoading) return
    fetchNextPage()
  }, [inView])

  const [isFollowed, setIsFollowed] = useState(userData?.is_subscribed)

  const mutationFollow = useMutation<void, Error>(() => {
    return followRequest(id!)
  })
  const mutationUnFollow = useMutation<void, Error>(() => {
    return unFollowRequest(id!)
  })

  useEffect(
    () => setIsFollowed(userData?.is_subscribed),
    [userData?.is_subscribed],
  )

  const followHandler = () => {
    if (isFollowed) {
      setIsFollowed(false)
      mutationUnFollow.mutate()
    } else {
      setIsFollowed(true)
      mutationFollow.mutate()
    }
  }

  return (
    <div className={c.profilePage}>
      <Container fluid>
        <div className={c.content}>
          <div className={c.profile}>
            <div className={c.information}>
              <div className={c.avatarWrapper}>
                {userData?.avatar && (
                  <img className={c.avatar} src={userData?.avatar} alt="" />
                )}
              </div>
              <div className={c.user}>
                <div className={c.nicknameWrapper}>
                  <p className={c.nickname}>
                    {userData?.surname && <span>{userData?.surname}</span>}
                    {userData?.name && <span>{userData?.name}</span>}
                    {!userData?.name && !userData?.surname && (
                      <span>{userData?.nickname}</span>
                    )}
                  </p>
                  {userData?.description && (
                    <p className={c.description}>{userData?.description}</p>
                  )}
                  {isMyProfile && (
                    <NavLink to="/settings" end className={c.settingsLink}>
                      <SettingIcon className={c.settingsIcon} />
                      {t('settings.heading')}
                    </NavLink>
                  )}
                </div>
                {!isMyProfile && !profileIsLoading && (
                  <button
                    type="button"
                    className={`${c.follow} ${isFollowed ? c.followed : ''}`}
                    onClick={followHandler}
                  >
                    {mutationFollow.isLoading ? (
                      t('general.loading')
                    ) : (
                      <>
                        {isFollowed && t('profile.unFollow')}
                        {!isFollowed && t('profile.follow')}
                      </>
                    )}
                  </button>
                )}
              </div>
            </div>
            <div className={c.rightPart}>
              <div className={c.statistics}>
                <div className={c.statistic}>
                  <div className={c.value}>
                    {userData?.subscriptions_count || 0}
                  </div>
                  <div className={c.title}>{t('profile.following')}</div>
                </div>
                <div className={c.statistic}>
                  <div className={c.value}>
                    {userData?.subscribers_count || 0}
                  </div>
                  <div className={c.title}>{t('profile.followers')}</div>
                </div>
                <div className={c.statistic}>
                  <div className={c.value}>{userData?.likes_count || 0}</div>
                  <div className={c.title}>{t('profile.likes')}</div>
                </div>
              </div>
              {!isMyProfile && !profileIsLoading && (
                <button
                  type="button"
                  className={`${c.follow} ${isFollowed ? c.followed : ''} ${
                    c.followMobile
                  }`}
                  onClick={followHandler}
                >
                  {mutationFollow.isLoading ? (
                    t('general.loading')
                  ) : (
                    <>
                      {isFollowed && t('profile.unFollow')}
                      {!isFollowed && t('profile.follow')}
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
          <div className={c.gridWrapper}>
            <div className={c.grid}>
              {postsData?.length! > 0 &&
                postsData?.map((elem) => (
                  <div key={elem.id} className={c.wrapper}>
                    {elem.assets?.[0].source_type === 'video' &&
                      elem.assets?.[0].url && (
                        <video
                          src={elem.assets?.[0].url}
                          className={c.image}
                          muted
                        />
                      )}
                    {elem.assets?.[0].source_type !== 'video' &&
                      elem.assets?.[0].url && (
                        <img
                          className={c.image}
                          src={elem.assets?.[0].url}
                          alt=""
                        />
                      )}
                    <NavLink className={c.link} end to={`/post/${elem.id}`} />
                  </div>
                ))}
            </div>
            {!isLoading && !isFetchingNextPage && hasNextPage && (
              <div ref={ref} />
            )}
            {isFetchingNextPage && (
              <div className={c.loaderWrapper}>
                <div className={c.loader}>
                  <Loader />
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ProfilePage
