import React, { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView, InView } from 'react-intersection-observer'
import { NavLink } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { ReactComponent as AddIcon } from '@material-design-icons/svg/filled/add.svg'

import {
  pushViewedPosts,
  synchronizeArrays,
} from 'store/reducers/viewedReducer'
import { sendViewedArray } from 'api'

import Container from 'components/common/Container'
import Tabs from 'components/Tabs'
import Post from 'components/Post'
import Loader from 'components/common/Loader'

import useInfiniteFetchPosts from 'hooks/query/useInfiniteFetchPosts'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import useLocationInfo from 'hooks/useLocationInfo'

import c from './TrendingVideoPage.module.scss'

function TrendingVideoPage() {
  const [isInitialRender, setIsInitialRender] = useState(true)
  const { freezedExceptions } = useAppSelector((state) => state.viewed)

  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const [viewedPosts, setViewedPosts] = useState<number[]>([])

  const mutationView = useMutation<void, Error>(() => {
    return sendViewedArray(viewedPosts)
  })

  const exceptionsRestructured = useMemo(
    () => freezedExceptions.map((elem) => elem.id),
    [freezedExceptions],
  )

  const {
    data: infinitePostsData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteFetchPosts({
    take: 3,
    type: 'trending',
    conclusion: 'video_only',
    exceptions: exceptionsRestructured,
    enabled: !isInitialRender,
  })

  const postsData = useMemo(() => {
    if (infinitePostsData === undefined) return []
    return infinitePostsData.pages.flatMap((p) => p)
  }, [infinitePostsData])

  const onScrollToEnd = () => {
    if (isLoading) return
    fetchNextPage()
  }

  const { pathname } = useLocationInfo()

  const indexOfCurrentPage = useMemo(() => {
    switch (pathname) {
      case '/trending':
        return 0
      case '/trending-video-art':
        return 1
      case '/feed':
        return 2

      default:
        return 0
    }
  }, [pathname])

  const viewHandle = (inView: boolean, postId: number) => {
    if (inView) {
      setViewedPosts((prev) => [...prev, postId])
    }
  }

  useEffect(() => {
    if (viewedPosts.length === 3) {
      mutationView.mutate()
      dispatch(pushViewedPosts(viewedPosts))
      setViewedPosts([])
    }
  }, [viewedPosts])

  useEffect(() => {
    setIsInitialRender(false)
    dispatch(synchronizeArrays())
  }, [])

  return (
    <div className={c.feedPage}>
      <Container flex noPaddingOnMobile>
        <div className={c.content}>
          <div className={c.settings}>
            <div className={c.switch}>
              {/* Desktop */}
              <NavLink
                to="/trending"
                className={`${c.switchVariant} ${
                  indexOfCurrentPage === 0 ? c.active : ''
                }`}
              >
                {t('trending.header')}
              </NavLink>
              <NavLink
                to="/trending-video-art"
                className={`${c.switchVariant} ${
                  indexOfCurrentPage === 1 ? c.active : ''
                }`}
              >
                {t('trending-video-art.header')}
              </NavLink>
              <NavLink
                to="/feed"
                className={`${c.switchVariant} ${
                  indexOfCurrentPage === 2 ? c.active : ''
                }`}
              >
                {t('feed.header')}
              </NavLink>
            </div>
            {/* Mobile */}
            <div className={c.topPage}>
              <div className={c.tabsWrapper}>
                <Tabs
                  indexOfCurrentPage={indexOfCurrentPage}
                  className={c.tabs}
                >
                  <div className={c.tabLink}>
                    {t('trending.header')}
                    <NavLink className={c.linkFill} to="/trending" end>
                      {t('trending.header')}
                    </NavLink>
                  </div>
                  <div className={c.tabLink}>
                    {t('trending-video-art.header')}
                    <NavLink className={c.linkFill} to="/trending-video-art">
                      {t('trending-video-art.header')}
                    </NavLink>
                  </div>
                  <div className={c.tabLink}>
                    {t('feed.header')}
                    <NavLink className={c.linkFill} to="/feed">
                      {t('feed.header')}
                    </NavLink>
                  </div>
                </Tabs>
              </div>
            </div>
            <NavLink to="/video-art" end className={c.button}>
              <>
                <AddIcon width="28px" height="28px" />
                {t('general.buttons.addVideo')}
              </>
            </NavLink>
          </div>
          <div className={c.feed}>
            {postsData?.length! > 0 &&
              postsData?.map((elem) => (
                <div key={elem.id}>
                  {/* trending */}
                  <Post post={elem} key={elem.id} />
                  <InView
                    as="div"
                    onChange={(inView) => viewHandle(inView, elem.id)}
                    triggerOnce
                  />
                </div>
              ))}
            {!isLoading && !isFetchingNextPage && hasNextPage && (
              <InView
                as="div"
                onChange={(inView) => inView && onScrollToEnd()}
              />
            )}
            {isFetchingNextPage && (
              <div className={c.loaderWrapper}>
                <div className={c.loader}>
                  <Loader />
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default TrendingVideoPage
