import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/common/Button'

import c from './GenerationFixedButton.module.scss'

interface GenerationFixedButtonProps {
  text: string
  onProcessingStart: () => void
  isImage?: boolean
}

function GenerationFixedButton({
  text,
  onProcessingStart,
  isImage = false
}: GenerationFixedButtonProps) {
  const { t } = useTranslation()

  return (
    <div className={c.fixed}>
      <Button
        className={c.button}
        disabled={text.length <= 0 || isImage}
        onClick={onProcessingStart}
      >
        {t('stages.generation.idle.buttonText')}
      </Button>
    </div>
  )
}

export default GenerationFixedButton
