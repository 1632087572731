import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Processor, { GenerateStrategy } from 'api/Retomagic'
import {
  changeCurrentAssetIndex,
  changeProcessedAssets,
  changeStage,
} from 'store/reducers/stageReducer'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import useFetchStyles from 'hooks/query/useFetchStyles'
import useFetchSuggestions from 'hooks/query/useFetchSuggestions'
import Container from 'components/common/Container'
import IdleStage from 'components/stages/generation/IdleStage'
import ProcessingStage from 'components/stages/generation/ProcessingStage'

import {
  ProcessedAssetsChangedPayload,
  ProcessorEvents,
} from 'types/ProcessorEvents'
import resolutionSizes from 'utils/resolutionSizes'

import c from './Generation.module.scss'

function Generation() {
  const dispatch = useAppDispatch()

  const { stage } = useAppSelector((state) => state.stage)

  const navigate = useNavigate()

  const [generationProcessor, setGenerationProcessor] =
    useState<Processor | null>(null)

  const { data: stylesData } = useFetchStyles()

  const { data: suggestionsData } = useFetchSuggestions()

  const [chosenStyle, setChosenStyle] = useState<string | undefined>('')

  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useState(false)
  const [text, setText] = useState<string>('')
  const [resolution, setResolution] = useState<number>(96)
  const [guidanceScale, setGuidanceScale] = useState<number>(7.5)
  const [seed, setSeed] = useState<string | undefined>(undefined)
  const [steps, setSteps] = useState<number>(50)
  const [negativePrompt, setNegativePrompt] = useState<string>('')

  const handleProcessedAssetsChanged = useCallback(
    (payload: ProcessedAssetsChangedPayload) => {
      const { assets, isFirstSucceededResult, id } = payload

      dispatch(changeProcessedAssets(assets))
      dispatch(changeStage('idle'))

      if (isFirstSucceededResult) {
        navigate(`/generation/finish/${id}`)
      }
    },
    [],
  )

  const handleProcessingStart = useCallback(async () => {
    dispatch(changeStage('processing'))

    const thisStyle = stylesData.find((elem) => elem.id === chosenStyle)

    const strategy = new GenerateStrategy(
      text,
      thisStyle?.title,
      thisStyle?.text,
      undefined,
      negativePrompt,
      steps,
      seed,
      guidanceScale,
      resolutionSizes[resolution].width || undefined,
      resolutionSizes[resolution].height || undefined,
    )

    const processor = new Processor(strategy)
    processor.on(
      ProcessorEvents.PROCESSED_ASSETS_CHANGED,
      handleProcessedAssetsChanged,
    )

    await processor.start()
    setGenerationProcessor(processor)
  }, [
    text,
    handleProcessedAssetsChanged,
    chosenStyle,
    stylesData,
    isAdvancedSettingsOpen,
    guidanceScale,
    seed,
    steps,
    resolution,
    negativePrompt,
  ])

  useEffect(() => {
    if (stage !== 'idle') return
    setText('')
    dispatch(changeProcessedAssets([]))
    dispatch(changeCurrentAssetIndex(0))
  }, [stage])

  useEffect(() => {
    document.documentElement.style.setProperty('--touch-action', 'none')

    return () => {
      document.documentElement.style.setProperty('--touch-action', 'pan-y')
    }
  }, [])

  const onExampleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setText(event.currentTarget?.value)
    },
    [],
  )

  const onStyleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (chosenStyle === event.currentTarget.value) {
        setChosenStyle(undefined)
      } else {
        setChosenStyle(event.currentTarget.value)
      }
    },
    [chosenStyle],
  )

  useEffect(() => {
    return () => {
      if (stage === 'processing') {
        dispatch(changeProcessedAssets([]))
        dispatch(changeCurrentAssetIndex(0))
        setText('')
        generationProcessor?.stop()
        setGenerationProcessor(null)
        dispatch(changeStage('idle'))
      }
    }
  }, [generationProcessor])

  return (
    <div className={c.main}>
      {stage === 'idle' && (
        <IdleStage
          isAdvancedSettingsOpen={isAdvancedSettingsOpen}
          setIsAdvancedSettingsOpen={setIsAdvancedSettingsOpen}
          negativePrompt={negativePrompt}
          setNegativePrompt={setNegativePrompt}
          text={text}
          onTextChange={setText}
          onProcessingStart={handleProcessingStart}
          onExampleClick={onExampleClick}
          onStyleClick={onStyleClick}
          chosenStyle={chosenStyle}
          suggestions={suggestionsData}
          styles={stylesData}
          resolution={resolution}
          setResolution={setResolution}
          guidanceScale={guidanceScale}
          setGuidanceScale={setGuidanceScale}
          seed={seed}
          setSeed={setSeed}
          steps={steps}
          setSteps={setSteps}
        />
      )}
      {stage === 'processing' && (
        <Container flex>
          <ProcessingStage />
        </Container>
      )}
    </div>
  )
}

export default Generation
