import React, { useMemo, useState } from 'react'

import Loader from 'components/common/Loader'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'hooks/store'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import useSave from 'hooks/useSave'
import useCopy from 'hooks/useCopy'
import { BaseAsset } from 'types/Response'
import { useMutation } from '@tanstack/react-query'
import { postPost } from 'api'
import { changeStage } from 'store/reducers/stageReducer'

import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check.svg'
import { ReactComponent as CreateIcon } from '@material-design-icons/svg/round/create.svg'
import { ReactComponent as CopyIcon } from '@material-design-icons/svg/filled/filter_none.svg'
import { ReactComponent as SyncIcon } from '@material-design-icons/svg/round/sync.svg'
import { ReactComponent as CloseIcon } from '@material-design-icons/svg/round/close.svg'
import { ReactComponent as RestartIcon } from '@material-design-icons/svg/round/replay.svg'
import { ReactComponent as DownloadIcon } from '@material-design-icons/svg/outlined/file_download.svg'
import { ReactComponent as ShareIcon } from '@material-design-icons/svg/outlined/ios_share.svg'
import { ReactComponent as ArrowNextIcon } from '@material-design-icons/svg/filled/arrow_forward_ios.svg'
import { ReactComponent as ArrowPrevIcon } from '@material-design-icons/svg/filled/arrow_back_ios.svg'
import { ReactComponent as LockIcon } from '@material-design-icons/svg/filled/lock.svg'

import IconButton from 'components/common/IconButton'
import BottomControls from 'components/BottomControls'
import Button from 'components/common/Button'

import c from './GenerationFinish.module.scss'

interface GenerationFinishProps {
  currentAssetIndex: number
  text?: string
  assets?: BaseAsset[]
  onGoBack: () => void
  sharedSlides: number[]
  onGenerateMore: () => void
  setSharedSlides: React.Dispatch<React.SetStateAction<number[]>>
  onTextChange: (newText: string) => void
}

function GenerationFinish({
  currentAssetIndex,
  onGoBack,
  assets = [],
  text = '',
  onGenerateMore,
  onTextChange,
  sharedSlides,
  setSharedSlides,
}: GenerationFinishProps) {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { data: user } = useFetchRequestMyProfile()

  const [currentSlide, setCurrentSlide] = useState<number>(currentAssetIndex)
  const { isSaved, handleSave } = useSave(assets?.[currentSlide]?.url)
  const { isCopied, handleCopy } = useCopy(text)

  const handleTextChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    onTextChange(e.currentTarget.value)
  }

  const mutation = useMutation<void, Error, number>((assetId) => {
    return postPost({ assets: [assetId!] })
  })

  const copyIcon = useMemo(() => {
    return isCopied ? (
      <CheckIcon style={{ fill: 'currentColor' }} />
    ) : (
      <CopyIcon style={{ fill: 'currentColor' }} />
    )
  }, [isCopied])

  const shareHandler = () => {
    mutation.mutate(assets?.[currentSlide]?.id)
    setSharedSlides((prev) => [...prev, currentSlide])
  }

  const variationsHandler = () => {
    dispatch(changeStage('idle'))
    navigate(`/magic-variations/${assets[currentSlide]?.id}`)
  }

  const prevHandler = () => {
    if (currentSlide > 0) setCurrentSlide((prev) => prev - 1)
  }

  const nextHandler = () => {
    if (currentSlide + 1 < assets?.length) setCurrentSlide((prev) => prev + 1)
  }

  const isThisSlideShared = useMemo(
    () => sharedSlides.includes(currentSlide),
    [sharedSlides, currentSlide],
  )

  return (
    <div className={c.finishingStage}>
      <div className={c.navigationPanel}>
        <IconButton onClick={onGoBack} className={c.goBackButton}>
          <CloseIcon style={{ fill: 'currentColor' }} />
        </IconButton>

        <IconButton onClick={handleSave} className={c.goBackButton}>
          <DownloadIcon style={{ fill: 'currentColor' }} />
        </IconButton>
      </div>
      <div className={c.content}>
        <div className={c.mainImageWrapper}>
          {assets?.[currentSlide]?.url && (
            <img
              src={assets?.[currentSlide]?.url}
              alt=""
              className={c.mainImage}
            />
          )}
        </div>

        <div className={c.rightPart}>
          <div className={c.controlPanel}>
            <div className={c.requestWrapper}>
              <textarea
                className={c.requestInput}
                name="textValue"
                value={text}
                onChange={handleTextChange}
              />
            </div>
            <div className={c.buttons}>
              <IconButton
                className={c.iconButton}
                disabled={isCopied}
                onClick={handleCopy}
              >
                <CreateIcon style={{ fill: 'currentColor' }} />
              </IconButton>
              <button
                type="button"
                className={c.button}
                onClick={onGenerateMore}
              >
                <RestartIcon style={{ fill: 'currentColor' }} />{' '}
                {t('general.buttons.repeat')}
              </button>
              <IconButton className={c.iconButton} onClick={handleCopy}>
                {copyIcon}
              </IconButton>
            </div>
            <BottomControls className={c.controls}>
              {user && (
                <Button
                  className={c.controlsButton}
                  startIcon={<ShareIcon style={{ fill: 'currentColor' }} />}
                  onClick={shareHandler}
                  disabled={isThisSlideShared}
                >
                  {isThisSlideShared
                    ? t('general.buttons.shared')
                    : t('general.buttons.justShare')}
                </Button>
              )}
              <Button
                variant="outlined"
                className={c.controlsButton}
                startIcon={<SyncIcon style={{ fill: 'currentColor' }} />}
                onClick={variationsHandler}
              >
                {t('stages.generation.finish.variations')}
              </Button>
            </BottomControls>
          </div>
          <div className={c.slider}>
            <div className={c.control}>
              <button type="button" className={c.arrow} onClick={prevHandler}>
                <ArrowPrevIcon />
              </button>
              <div className={c.number}>
                {currentSlide + 1}/{assets?.length}
              </div>
              <button type="button" className={c.arrow} onClick={nextHandler}>
                <ArrowNextIcon />
              </button>
            </div>
            <div className={c.sliders}>
              {assets?.length > 0 &&
                assets?.map((asset, index) => {
                  return (
                    <button
                      className={c.slideWrapper}
                      type="button"
                      key={asset.id}
                      onClick={() => setCurrentSlide(index)}
                    >
                      {asset?.url && (
                        <img src={asset?.url} alt="" className={c.image} />
                      )}
                      {!asset?.url && (
                        <div className={c.loaderWrapper}>
                          <Loader />
                        </div>
                      )}
                      {false && (
                        <div className={c.blocked}>
                          <LockIcon
                            width="40px"
                            height="40px"
                            style={{ fill: 'currentColor' }}
                          />
                          <span>{t('stages.generation.finish.blocked')}</span>
                        </div>
                      )}
                    </button>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GenerationFinish
