import React, { useMemo } from 'react'
import c from './BottomControls.module.scss'

interface BottomControlsProps {
  children: React.ReactNode
  className?: string
}

function BottomControls({
  children,
  className = undefined,
}: BottomControlsProps) {
  const classNames = useMemo(() => {
    let result = c.controls
    if (className) result += ` ${className}`
    return result
  }, [className])

  return <div className={classNames}>{children}</div>
}

export default BottomControls
