import { UserInfo } from 'firebase/auth'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AuthState {
  user: UserInfo | null
  isAuthInitialized: boolean
}

const initialState: AuthState = {
  user: null,
  isAuthInitialized: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserInfo | null>) => {
      state.user = action.payload
    },
    initialize: (state) => {
      state.isAuthInitialized = true
    },
  },
})

export const { setUser, initialize } = authSlice.actions

export default authSlice.reducer
