/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-alert */
import React, { useMemo, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { NavLink } from 'react-router-dom'
import { ReactComponent as CopyIcon } from '@material-design-icons/svg/outlined/content_copy.svg'
import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check.svg'
import { ReactComponent as FavoriteIcon } from '@material-design-icons/svg/outlined/favorite.svg'
import { ReactComponent as FavoriteBorderIcon } from '@material-design-icons/svg/outlined/favorite_border.svg'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
// import { unlike } from 'api'
import {
  adminDeleteEverywhere,
  adminHideTrends,
  likeRequest,
  unlikeRequest,
  deletePost,
} from 'api'
import ReactTimeAgo from 'react-time-ago'
import { PostFeed } from 'types/Post'
import IconButton from 'components/common/IconButton'
import useCopy from 'hooks/useCopy'

import c from './Post.module.scss'

interface PostProps {
  post: PostFeed
  imgClassName?: string
  withLink?: boolean
  trending?: boolean
}

function Post({
  post,
  imgClassName = '',
  withLink = false,
  trending = false,
}: PostProps) {
  const { t } = useTranslation()
  const [isLiked, setIsLiked] = useState<boolean>(post.is_liked)
  const [likesCount, setLikesCount] = useState<number>(post.likes_count)
  const { isCopied, handleCopy } = useCopy(post.assets?.[0]?.source)

  const { data: user } = useFetchRequestMyProfile()
  const mutationLike = useMutation<void, Error>(() => {
    return likeRequest(post.id!)
  })
  const mutationUnLike = useMutation<void, Error>(() => {
    return unlikeRequest(post.id!)
  })
  const mutationDeleteByAdmin = useMutation<void, Error>(() => {
    return adminDeleteEverywhere(post.id!)
  })
  const mutationHide = useMutation<void, Error>(() => {
    return adminHideTrends(post.id!)
  })
  const mutationDelete = useMutation<void, Error>(() => {
    return deletePost(post.id!)
  })

  const copyIcon = useMemo(() => {
    return isCopied ? (
      <CheckIcon className={c.icon} style={{ fill: 'currentColor' }} />
    ) : (
      <CopyIcon className={c.icon} style={{ fill: 'currentColor' }} />
    )
  }, [isCopied])

  const likeHandler = () => {
    if (isLiked) {
      setLikesCount((prev) => prev - 1)
      setIsLiked(false)
      mutationUnLike.mutate()
    } else {
      setLikesCount((prev) => prev + 1)
      setIsLiked(true)
      mutationLike.mutate()
    }
  }

  const deleteEverywhere = () => {
    const result = window.confirm('Are you sure want to delete this post?')
    if (result) {
      mutationDeleteByAdmin.mutate()
      toast.success('Everything went well')
    }
  }

  const deleteYourPost = () => {
    const result = window.confirm('Are you sure want to delete this post?')
    if (result) {
      mutationDelete.mutate()
      toast.success('Everything went well')
    }
  }

  const hideTrends = () => {
    const result = window.confirm('Are you sure want to hide this post?')
    if (result) {
      mutationHide.mutate()
      toast.success('Everything went well')
    }
  }

  return (
    <div className={c.post}>
      <div className={c.postTop}>
        <div className={c.postUser}>
          <div className={c.avatarWrapper}>
            {post.user.avatar && (
              <img className={c.avatar} src={post.user.avatar} alt="" />
            )}
          </div>
          <div className={c.nicknameWrapper}>
            <p className={c.nickname}>{post.user.nickname}</p>
            {post.created_at && (
              <p className={c.date}>
                <ReactTimeAgo date={new Date(post.created_at)} locale="en-US" />
              </p>
            )}
          </div>
          <NavLink className={c.link} to={`/profile/${post.user.id}`} end />
        </div>
        {user?.role === 'admin' && (
          <div className={c.admin}>
            <button
              type="button"
              className={c.delete}
              onClick={deleteEverywhere}
            >
              Delete everywhere
            </button>
            <button type="button" className={c.delete} onClick={hideTrends}>
              Hide trends
            </button>
          </div>
        )}
        {post.user.id === Number(user?.id) && (
          <div className={c.admin}>
            <button type="button" className={c.delete} onClick={deleteYourPost}>
              Delete
            </button>
          </div>
        )}
      </div>
      <div
        className={`${
          trending || post.assets?.[0].source_type === 'video' ? c.postImgWrapperTrending : c.postImgWrapper
        } ${imgClassName}`}
      >
        {post.assets?.[0].source_type === 'video' && post.assets?.[0].url && (
          <>
            <video
              src={post.assets?.[0].url}
              className={c.postImg}
              controls
              loop
            />
            {/* <NavLink to={`/post/${post.id}`} end className={c.linkForVideo} /> */}
          </>
        )}
        {post.assets?.[0].source_type !== 'video' && post.assets?.[0].url && (
          <>
            <img className={c.postImg} src={post.assets?.[0].url} alt="" />
            <NavLink to={`/post/${post.id}`} end className={c.link} />
          </>
        )}
      </div>
      <div className={c.postFeedBack}>
        <div
          className={c.postLikes}
          onClick={likeHandler}
          onKeyDown={likeHandler}
          role="button"
          tabIndex={0}
        >
          <div className={c.postLikesIcon}>
            <FavoriteBorderIcon />
            <div className={`${c.favoredIcon} ${isLiked ? c.favored : ''}`}>
              <FavoriteIcon />
            </div>
          </div>
          <p className={c.postLikesCount}>{likesCount}</p>
        </div>
        <div className={c.postViews}>{`${post.views_count} ${t(
          'profile.views',
          { count: Number(post.views_count) },
        )}`}</div>
      </div>
      <div className={c.postBottom}>
        <div className={c.text}>
          {post.assets?.[0]?.source}
          {post.assets?.[0]?.style && (
            <span className={c.textStyle}>{post.assets?.[0]?.style}</span>
          )}
        </div>
        <IconButton
          className={c.postCopy}
          disabled={isCopied}
          onClick={handleCopy}
        >
          {copyIcon}
        </IconButton>
      </div>
    </div>
  )
}

export default Post
