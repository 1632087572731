import React, { useCallback } from 'react'
import { Outlet, ScrollRestoration, useNavigate } from 'react-router-dom'

import { ReactComponent as ArrowBackIcon } from '@material-design-icons/svg/round/arrow_back_ios.svg'

import Container from 'components/common/Container'
import IconButton from 'components/common/IconButton'

import c from './SettingsPage.module.scss'

function SettingsPage() {
  const navigate = useNavigate()

  const onGoBack = useCallback(() => {
    navigate(-1)
  }, [])

  return (
    <main className={c.main}>
      <Container>
        <div className={c.content}>
          <IconButton onClick={onGoBack} className={c.goBackButton}>
            <ArrowBackIcon style={{ fill: 'currentColor' }} />
          </IconButton>
          <Outlet />
        </div>
      </Container>
    </main>
  )
}

export default SettingsPage
