/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check.svg'
import { ReactComponent as CreateIcon } from '@material-design-icons/svg/round/create.svg'
import { ReactComponent as CopyIcon } from '@material-design-icons/svg/filled/filter_none.svg'
import { ReactComponent as SyncIcon } from '@material-design-icons/svg/round/sync.svg'
import { ReactComponent as CloseIcon } from '@material-design-icons/svg/round/close.svg'
import { ReactComponent as RestartIcon } from '@material-design-icons/svg/round/replay.svg'
import { ReactComponent as DownloadIcon } from '@material-design-icons/svg/outlined/file_download.svg'
import { ReactComponent as ShareIcon } from '@material-design-icons/svg/outlined/ios_share.svg'
import { ReactComponent as ArrowNextIcon } from '@material-design-icons/svg/filled/arrow_forward_ios.svg'
import { ReactComponent as ArrowPrevIcon } from '@material-design-icons/svg/filled/arrow_back_ios.svg'
import { ReactComponent as LockIcon } from '@material-design-icons/svg/filled/lock.svg'
import Loader from 'components/common/Loader'

import { useAppDispatch } from 'hooks/store'
import { changeStage } from 'store/reducers/stageReducer'
import useCopy from 'hooks/useCopy'
import useSave from 'hooks/useSave'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import { postPost } from 'api'
import { BaseAsset } from 'types/Response'

import Button from 'components/common/Button'
import IconButton from 'components/common/IconButton'
import BottomControls from 'components/BottomControls'

import c from './FinishStage.module.scss'

interface FinishStageProps {
  text?: string
  currentAssetUrl: string | undefined
  currentAssetIndex: number
  assets?: BaseAsset[]
  sharedSlides: number[]
  setSharedSlides: React.Dispatch<React.SetStateAction<number[]>>
  onGenerateMore: () => void
  onGoBack: () => void
  onTextChange: (newText: string) => void
}

function FinishStage({
  text = '',
  currentAssetUrl,
  currentAssetIndex,
  assets = [],
  sharedSlides,
  setSharedSlides,
  onGenerateMore,
  onGoBack,
  onTextChange,
}: FinishStageProps) {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { data: user } = useFetchRequestMyProfile()

  // const videoPlayer = useRef<any>(null)
  // const audioPlayer = useRef<any>(null)

  const [assetsSuccess, setAssetsSuccess] = useState<BaseAsset[]>([])

  useEffect(() => {
    assets.forEach((elem, index) => {
      if (
        elem.status === 'success' &&
        !assetsSuccess.find((el) => el?.id === elem?.id)
      ) {
        setAssetsSuccess((prev) => {
          const newArr = [...prev]
          newArr[index] = elem
          return newArr
        })
      }
    })
  }, [assets])

  const [firstProcessedAssetIndex, setFirstProcessedAssetIndex] = useState<
    number | undefined
  >(undefined)

  const [currentSlide, setCurrentSlide] = useState<number>(currentAssetIndex)

  const { isSaved, handleSave } = useSave(assets?.[currentSlide]?.url)

  const { isCopied, handleCopy } = useCopy(text)

  const mutation = useMutation<void, Error, number>((assetId) => {
    return postPost({ assets: [assetId!] })
  })

  const shareHandler = () => {
    mutation.mutate(assets?.[currentSlide]?.id)
    setSharedSlides((prev) => [...prev, currentSlide])
  }

  const variationsHandler = () => {
    dispatch(changeStage('idle'))
    navigate(`/magic-variations/${assets?.[currentSlide]?.id}`)
  }

  const copyIcon = useMemo(() => {
    return isCopied ? (
      <CheckIcon style={{ fill: 'currentColor' }} />
    ) : (
      <CopyIcon style={{ fill: 'currentColor' }} />
    )
  }, [isCopied])

  const handleTextChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    onTextChange(e.currentTarget.value)
  }

  useEffect(() => {
    if (firstProcessedAssetIndex) return
    const processedAsset = assets?.findIndex((a) => a.url)
    if (processedAsset) {
      setFirstProcessedAssetIndex(processedAsset)
    }
  }, [assets])

  const prevHandler = () => {
    if (currentSlide > 0) setCurrentSlide((prev) => prev - 1)
  }

  const nextHandler = () => {
    if (currentSlide + 1 < assets?.length) setCurrentSlide((prev) => prev + 1)
  }

  const isThisSlideShared = useMemo(
    () => sharedSlides.includes(currentSlide),
    [sharedSlides, currentSlide],
  )

  const onRepeat = () => {
    setAssetsSuccess([])
    onGenerateMore()
  }

  // const onVideoPlay = () => {
  //   audioPlayer.current.play()
  // }
  // const onVideoPause = () => {
  //   audioPlayer.current.pause()
  // }

  return (
    <div className={c.finishingStage}>
      <div className={c.navigationPanel}>
        <IconButton onClick={onGoBack} className={c.goBackButton}>
          <CloseIcon style={{ fill: 'currentColor' }} />
        </IconButton>

        <IconButton onClick={handleSave} className={c.goBackButton}>
          <DownloadIcon style={{ fill: 'currentColor' }} />
        </IconButton>
      </div>
      <div className={c.content}>
        <div className={c.mainImageWrapper}>
          {/* {assets?.[currentSlide]?.url && (
            <img
              src={assets?.[currentSlide]?.url}
              alt=""
              className={c.mainImage}
            />
          )} */}
          {assetsSuccess?.[currentSlide]?.url && (
            <>
              <video
                src={assetsSuccess?.[currentSlide]?.url}
                className={c.mainImage}
                controls
                loop
                autoPlay
                // ref={videoPlayer}
                // onPlay={onVideoPlay}
                // onPause={onVideoPause}
                // muted
              />
              {/* <audio
                src={assetsSuccess?.[currentSlide]?.settings?.audio_url}
                loop
                autoPlay
                ref={audioPlayer}
              /> */}
            </>
          )}
        </div>

        <div className={c.rightPart}>
          <div className={c.controlPanel}>
            <div className={c.requestWrapper}>
              <textarea
                className={c.requestInput}
                name="textValue"
                value={text}
                onChange={handleTextChange}
              />
            </div>
            <div className={c.buttons}>
              <IconButton
                className={c.iconButton}
                disabled={isCopied}
                onClick={handleCopy}
              >
                <CreateIcon style={{ fill: 'currentColor' }} />
              </IconButton>
              <button type="button" className={c.button} onClick={onRepeat}>
                <RestartIcon style={{ fill: 'currentColor' }} />{' '}
                {t('general.buttons.repeat')}
              </button>
              <IconButton className={c.iconButton} onClick={handleCopy}>
                {copyIcon}
              </IconButton>
            </div>
            <BottomControls className={c.controls}>
              {user && (
                <Button
                  className={c.controlsButton}
                  startIcon={<ShareIcon style={{ fill: 'currentColor' }} />}
                  onClick={shareHandler}
                  disabled={isThisSlideShared}
                >
                  {isThisSlideShared
                    ? t('general.buttons.shared')
                    : t('general.buttons.justShare')}
                </Button>
              )}
              {/* <Button
                variant="outlined"
                className={c.controlsButton}
                startIcon={<SyncIcon style={{ fill: 'currentColor' }} />}
                onClick={variationsHandler}
              >
                {t('stages.generation.finish.variations')}
              </Button> */}
            </BottomControls>
          </div>
          <div className={c.slider}>
            <div className={c.control}>
              <button type="button" className={c.arrow} onClick={prevHandler}>
                <ArrowPrevIcon />
              </button>
              <div className={c.number}>
                {currentSlide + 1}/{assets?.length}
              </div>
              <button type="button" className={c.arrow} onClick={nextHandler}>
                <ArrowNextIcon />
              </button>
            </div>
            <div className={c.sliders}>
              {assets?.length > 0 &&
                assets?.map((asset, index) => {
                  return (
                    <button
                      className={c.slideWrapper}
                      type="button"
                      key={asset.id}
                      onClick={() => setCurrentSlide(index)}
                    >
                      {asset?.url && assetsSuccess?.[index]?.url && (
                        <video
                          src={assetsSuccess?.[index]?.url}
                          className={c.image}
                          muted
                        />
                      )}
                      {!asset?.url && (
                        <div className={c.loaderWrapper}>
                          <Loader />
                        </div>
                      )}
                      {false && (
                        <div className={c.blocked}>
                          <LockIcon
                            width="40px"
                            height="40px"
                            style={{ fill: 'currentColor' }}
                          />
                          <span>{t('stages.generation.finish.blocked')}</span>
                        </div>
                      )}
                    </button>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FinishStage
