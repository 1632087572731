import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ThumbUpIcon } from '@material-design-icons/svg/outlined/thumb_up.svg'
import { ReactComponent as ThumbDownIcon } from '@material-design-icons/svg/outlined/thumb_down.svg'
import { ReactComponent as SyncIcon } from '@material-design-icons/svg/round/sync.svg'
import { ReactComponent as CloseIcon } from '@material-design-icons/svg/round/close.svg'
import { ReactComponent as RestartIcon } from '@material-design-icons/svg/round/replay.svg'
import IconButton from 'components/common/IconButton'
import ImageComparison from 'components/ImageComparison'
import BottomControls from 'components/BottomControls'
import ShareButton from 'components/ShareButton'
import SaveButton from 'components/SaveButton'
import ImageInput from 'components/ImageInput'
import { Rating } from 'types/Rating'
import c from './FinishStage.module.scss'

interface FinishStageProps {
  imageUrl: string
  processedImageUrl: string
  rating: Rating | null
  onRatingChange: (newRating: Rating | null) => void
  onEnhanceMore?: (newFile: File) => void
  onGoBack?: () => void
}

function FinishStage({
  imageUrl,
  processedImageUrl,
  rating,
  onRatingChange,
  onEnhanceMore = undefined,
  onGoBack = () => {},
}: FinishStageProps) {
  const { t } = useTranslation()

  const ratingText = useMemo<string>(() => {
    return rating !== null
      ? t('stages.enhancement.finish.ratingTextSuccess')
      : t('stages.enhancement.finish.ratingText')
  }, [t, rating])

  const handleLike = useCallback(() => {
    onRatingChange('like')
  }, [onRatingChange])

  const handleDislike = useCallback(() => {
    onRatingChange('dislike')
  }, [onRatingChange])

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onEnhanceMore == null) return

      const file = e.target.files?.[0]
      if (file) onEnhanceMore(file)
    },
    [onEnhanceMore],
  )

  return (
    <div className={c.finishStage}>
      <div className={c.navigationPanel}>
        <IconButton onClick={onGoBack} className={c.goBackButton}>
          <CloseIcon style={{ fill: 'currentColor' }} />
        </IconButton>
        <IconButton onClick={onGoBack} className={c.goBackButton}>
          <RestartIcon style={{ fill: 'currentColor' }} />
        </IconButton>
      </div>
      <div className={c.content}>
        <ImageComparison
          imageUrl={imageUrl}
          processedImageUrl={processedImageUrl}
        />
        <div className={c.controlPanel}>
          <div className={c.rating}>
            {rating === null && (
              <IconButton onClick={handleLike}>
                <ThumbUpIcon style={{ fill: 'currentColor' }} />
              </IconButton>
            )}
            <p className={c.ratingText}>{ratingText}</p>
            {rating === null && (
              <IconButton onClick={handleDislike}>
                <ThumbDownIcon style={{ fill: 'currentColor' }} />
              </IconButton>
            )}
          </div>
          <BottomControls className={c.controls}>
            {onEnhanceMore ? (
              <ImageInput
                className={c.controlsButton}
                label={t('general.buttons.enhanceMore')}
                startIcon={<SyncIcon style={{ fill: 'currentColor' }} />}
                onChange={handleInputChange}
              />
            ) : (
              <ShareButton
                className={c.controlsButton}
                imageUrl={processedImageUrl}
              />
            )}
            <SaveButton
              className={c.controlsButton}
              imageUrl={processedImageUrl}
            />
          </BottomControls>
        </div>
      </div>
    </div>
  )
}

export default FinishStage
