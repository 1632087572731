import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  changeCurrentAssetIndex,
  changeProcessedAssets,
  changeStage,
} from 'store/reducers/stageReducer'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import useFetchRequest from 'hooks/query/useFetchRequest'
import {
  ProcessedAssetsChangedPayload,
  ProcessorEvents,
} from 'types/ProcessorEvents'
import Processor, { VideoStrategy } from 'api/Retomagic'

import FinishStage from 'components/stages/video/FinishStage'
import ProcessingStage from 'components/stages/video/ProcessingStage'
import Container from 'components/common/Container'

import c from './VideoArtFinishPage.module.scss'

function VideoArtFinishPage() {
  const { stage, processedAssets, currentAssetIndex } = useAppSelector(
    (state) => state.stage,
  )
  const dispatch = useAppDispatch()

  const [generationProcessor, setGenerationProcessor] =
    useState<Processor | null>(null)

  const [text, setText] = useState<string | undefined>('')
  const [sharedSlides, setSharedSlides] = useState<number[]>([])

  const navigate = useNavigate()

  const { requestId } = useParams()
  const { data, isLoading, isSuccess, isError, error } = useFetchRequest({
    requestId,
  })

  useEffect(() => {
    if (sharedSlides.length > 0) setSharedSlides([])
  }, [requestId])

  const handleProcessedAssetsChanged = useCallback(
    (payload: ProcessedAssetsChangedPayload) => {
      const { assets, isFirstSucceededResult, id } = payload

      dispatch(changeProcessedAssets(assets))
      dispatch(changeStage('idle'))

      if (isFirstSucceededResult) {
        navigate(`/video-art/finish/${id}`)
      }
    },
    [],
  )

  const handleProcessingStart = useCallback(async () => {
    dispatch(changeStage('processing'))

    const strategy = new VideoStrategy(text!)
    const processor = new Processor(strategy)
    processor.on(
      ProcessorEvents.PROCESSED_ASSETS_CHANGED,
      handleProcessedAssetsChanged,
    )
    await processor.start()
    setGenerationProcessor(processor)
  }, [text, handleProcessedAssetsChanged, data])

  const handleGoBack = useCallback(() => {
    dispatch(changeStage('idle'))
    dispatch(changeProcessedAssets([]))
    dispatch(changeCurrentAssetIndex(0))
    navigate('/video-art')
  }, [])

  useEffect(() => {
    setText(data?.assets?.[0]?.source)
  }, [data])

  const currentAssetUrl = useMemo(() => {
    const currentAsset = processedAssets[currentAssetIndex]
    return currentAsset?.url
  }, [processedAssets, currentAssetIndex])

  useEffect(() => {
    return () => {
      if (stage === 'processing') {
        dispatch(changeProcessedAssets([]))
        dispatch(changeCurrentAssetIndex(0))
        setText('')
        generationProcessor?.stop()
        setGenerationProcessor(null)
        dispatch(changeStage('idle'))
      }
    }
  }, [generationProcessor])


  return (
    <div className={c.generationFinishPage}>
      {stage === 'idle' && (
        <div className={c.container}>
          <FinishStage
            text={text}
            currentAssetUrl={currentAssetUrl}
            currentAssetIndex={currentAssetIndex}
            assets={data?.assets}
            sharedSlides={sharedSlides}
            setSharedSlides={setSharedSlides}
            onTextChange={setText}
            onGenerateMore={handleProcessingStart}
            onGoBack={handleGoBack}
          />
        </div>
      )}
      {stage === 'processing' && (
        <Container flex>
          <ProcessingStage />
        </Container>
      )}
    </div>
  )
}

export default VideoArtFinishPage
