import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'

import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import useModal from 'hooks/useModal'

import { clearExpired } from 'store/reducers/viewedReducer'
import {
  setLogInModalOpen,
  setSignUpModalOpen,
  setPromoModalOpen,
  setUserModalOpen,
} from 'store/reducers/modalReducer'

import PromoModal from 'components/modals/PromoModal'
import LogInModal from 'components/modals/LogInModal'
import SignUpModal from 'components/modals/SignUpModal'
import UserModal from 'components/modals/UserModal'
import Toastify from 'components/helpers/Toastify'
import ScrollToTop from 'components/helpers/ScrollToTop'
import LayoutHeader from './LayoutHeader'
import LayoutBottom from './LayoutBottom'

import c from './MainLayout.module.scss'

function MainLayout() {
  const dispatch = useAppDispatch()
  const deviceId = useAppSelector((state) => state.settings.deviceId)
  const { logInModalOpen, promoModalOpen, signUpModalOpen, userModalOpen } =
    useAppSelector((state) => state.modal)

  TimeAgo.addLocale(en)
  TimeAgo.addLocale(ru)

  const { open: openLogInModal, close: closeLogInModal } = useModal(
    logInModalOpen,
    setLogInModalOpen,
  )

  const { open: openPromoModal, close: closePromoModal } = useModal(
    promoModalOpen,
    setPromoModalOpen,
  )

  const { open: openSignUpModal, close: closeSignUpModal } = useModal(
    signUpModalOpen,
    setSignUpModalOpen,
  )

  const { open: openUserModal, close: closeUserModal } = useModal(
    userModalOpen,
    setUserModalOpen,
  )

  const { data: userData } = useFetchRequestMyProfile()

  useEffect(() => {
    if (userData && !userData?.nickname) {
      openUserModal()
    }
  }, [userData])

  useEffect(() => {
    const isShown = window.localStorage.getItem('neuro-art-promo-modal-shown')
    if (isShown) return
    window.localStorage.setItem('neuro-art-promo-modal-shown', 'true')
    openPromoModal()
  }, [openPromoModal])

  useEffect(() => {
    window.localStorage.setItem('device-id', deviceId)
  }, [deviceId])

  useEffect(() => {
    dispatch(clearExpired())
  }, [])

  return (
    <div className={c.layout}>
      <ScrollToTop />
      <LayoutHeader />
      <div className={c.contentWrapper}>
        <Outlet />
      </div>
      <LayoutBottom />
      <PromoModal open={promoModalOpen} onClose={closePromoModal} />
      <LogInModal
        open={logInModalOpen}
        onClose={closeLogInModal}
        onSwitch={openSignUpModal}
      />
      <SignUpModal
        open={signUpModalOpen}
        onClose={closeSignUpModal}
        onSwitch={openLogInModal}
      />
      <UserModal open={userModalOpen} onClose={closeUserModal} />
      <Toastify />
    </div>
  )
}

export default MainLayout
