import React, { useCallback } from 'react'
import TextInput from 'components/common/TextInput'
import { ReactComponent as AutoFixIcon } from '@material-design-icons/svg/round/auto_fix_high.svg'
import { useTranslation } from 'react-i18next'

import c from './GenerationInput.module.scss'

interface GenerationInputProps {
  text: string
  onTextChange: (newText: string) => void
  onProcessingStart: () => void
}

function GenerationInput({
  text,
  onTextChange,
  onProcessingStart,
}: GenerationInputProps) {
  const { t } = useTranslation()

  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onTextChange(e.target.value)
    },
    [onTextChange],
  )

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') return
      onProcessingStart()
    },
    [onProcessingStart],
  )

  return (
    <>
      <p className={c.heading}>{t('stages.generation.idle.inputLabel')}</p>
      <TextInput
        className={c.input}
        placeholder={t('stages.generation.idle.inputPlaceholder')}
        startIcon={<AutoFixIcon style={{ fill: 'currentColor' }} />}
        value={text}
        onChange={handleTextChange}
        onKeyDown={handleKeyDown}
      />
    </>
  )
}

export default GenerationInput
