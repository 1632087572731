import React from 'react'
import { useTranslation } from 'react-i18next'
import InputRange from 'react-input-range'

import c from './PowerSelection.module.scss'

interface PowerSelectionProps {
  strength: number
  setStrength: (value: any) => void
}

function PowerSelection({ strength, setStrength }: PowerSelectionProps) {
  const { t } = useTranslation()
  return (
    <div className={c.powerSelection}>
      <p className={c.heading}>{t('stages.variations.idle.chosePower')}</p>
      <div className={c.rangeWrapper}>
        <InputRange
          maxValue={100}
          minValue={0}
          value={strength}
          onChange={(value) => setStrength(value)}
        />
        <div
          className={c.rangeCircle}
          style={{
            backgroundColor:
              strength > 25
                ? 'hsla(295, 100%, 42%, 1)'
                : 'hsla(295, 100%, 18%, 1)',
          }}
        />
        <div
          className={c.rangeCircle}
          style={{
            backgroundColor:
              strength > 50
                ? 'hsla(295, 100%, 42%, 1)'
                : 'hsla(295, 100%, 18%, 1)',
          }}
        />
        <div
          className={c.rangeCircle}
          style={{
            backgroundColor:
              strength > 75
                ? 'hsla(295, 100%, 42%, 1)'
                : 'hsla(295, 100%, 18%, 1)',
          }}
        />
        <div
          className={c.rangeCircle}
          style={{
            backgroundColor:
              strength === 100
                ? 'hsla(295, 100%, 42%, 1)'
                : 'hsla(295, 100%, 18%, 1)',
          }}
        />
        <div className={c.rangeCircle} />
      </div>
    </div>
  )
}

export default PowerSelection
