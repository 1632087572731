import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check.svg'
import { ReactComponent as DownloadIcon } from '@material-design-icons/svg/outlined/file_download.svg'
import Button from 'components/common/Button'
import useSave from 'hooks/useSave'

interface SaveButtonProps {
  imageUrl: string | undefined
  className?: string
}

function SaveButton({ imageUrl, className = undefined }: SaveButtonProps) {
  const { t } = useTranslation()

  const { isSaved, handleSave } = useSave(imageUrl)

  const saveText = useMemo<string>(() => {
    return isSaved
      ? t('general.buttons.saveSuccess')
      : t('general.buttons.save')
  }, [t, isSaved])

  const saveIcon = useMemo<React.ReactNode>(() => {
    return isSaved ? (
      <CheckIcon style={{ fill: 'currentColor' }} />
    ) : (
      <DownloadIcon style={{ fill: 'currentColor' }} />
    )
  }, [isSaved])

  const saveStyles = useMemo<React.CSSProperties>(() => {
    return isSaved ? { pointerEvents: 'none' } : { pointerEvents: undefined }
  }, [isSaved])

  return (
    <Button
      className={className}
      style={saveStyles}
      variant="outlined"
      startIcon={saveIcon}
      onClick={handleSave}
    >
      {saveText}
    </Button>
  )
}

export default SaveButton
