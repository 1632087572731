import React from 'react'
import { Suggestion, Style } from 'types/Generation'
import Container from 'components/common/Container'
import GenerationInput from 'components/GenerationInput'
import Suggestions from 'components/Suggestions'
import GenerationStyles from 'components/GenerationStyles'
import GenerationFixedButton from 'components/GenerationFixedButton'

import c from './IdleStage.module.scss'

interface IdleStageProps {
  text: string
  suggestions?: Suggestion[]
  styles?: Style[]
  chosenStyle: string | undefined
  onTextChange: (newText: string) => void
  onProcessingStart: () => void
  onExampleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  onStyleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

function IdleStage({
  text,
  suggestions = [],
  styles = [],
  chosenStyle,
  onTextChange,
  onProcessingStart,
  onExampleClick,
  onStyleClick,
}: IdleStageProps) {
  return (
    <Container flex>
      <div className={c.content}>
        <GenerationInput
          text={text}
          onTextChange={onTextChange}
          onProcessingStart={onProcessingStart}
        />
        <Suggestions
          suggestions={suggestions}
          onExampleClick={onExampleClick}
        />
        <GenerationStyles
          chosenStyle={chosenStyle}
          styles={styles}
          onStyleClick={onStyleClick}
        />
      </div>
      <GenerationFixedButton
        text={text}
        onProcessingStart={onProcessingStart}
      />
    </Container>
  )
}

export default IdleStage
