import React from 'react'

// eslint-disable-next-line react/require-default-props
function ActivityFeed(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 48 48"
      fill="none"
      {...props}
    >
      <path d="M 11.5 6 C 8.4802259 6 6 8.4802259 6 11.5 L 6 36.5 C 6 39.519774 8.4802259 42 11.5 42 L 36.5 42 C 39.519774 42 42 39.519774 42 36.5 L 42 11.5 C 42 8.4802259 39.519774 6 36.5 6 L 11.5 6 z M 11.5 9 L 36.5 9 C 37.898226 9 39 10.101774 39 11.5 L 39 36.5 C 39 37.898226 37.898226 39 36.5 39 L 11.5 39 C 10.101774 39 9 37.898226 9 36.5 L 9 11.5 C 9 10.101774 10.101774 9 11.5 9 z M 14.5 14 A 1.50015 1.50015 0 1 0 14.5 17 L 33.5 17 A 1.50015 1.50015 0 1 0 33.5 14 L 14.5 14 z M 14.5 22.5 A 1.50015 1.50015 0 1 0 14.5 25.5 L 33.5 25.5 A 1.50015 1.50015 0 1 0 33.5 22.5 L 14.5 22.5 z M 14.5 31 A 1.50015 1.50015 0 1 0 14.5 34 L 33.5 34 A 1.50015 1.50015 0 1 0 33.5 31 L 14.5 31 z" />
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h18v22H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ActivityFeed
