import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Processor, { EnhanceStrategy } from 'api/Retomagic'
import { useAppDispatch, useAppSelector } from 'hooks/store'

import Container from 'components/common/Container'
import IdleStage from 'components/stages/enhancement/IdleStage'
import PreviewStage from 'components/stages/enhancement/PreviewStage'
import ProcessingStage from 'components/stages/enhancement/ProcessingStage'

import {
  changeCurrentAssetIndex,
  changeProcessedAssets,
  changeStage,
} from 'store/reducers/stageReducer'
import { HANDLER_OPTIONS } from 'config/handlers'
import { Rating } from 'types/Rating'
import { OutputFormat } from 'types/OutputFormat'
import Handlers from 'types/Handlers'
import {
  ProcessedAssetsChangedPayload,
  ProcessorEvents,
} from 'types/ProcessorEvents'
import c from './EnhancementPage.module.scss'

function EnhancementPage({ handler }: { handler: `${Handlers}` }) {
  const dispatch = useAppDispatch()
  const { stage, processedAssets } = useAppSelector((state) => state.stage)

  const navigate = useNavigate()

  const [outputFormat, setOutputFormat] = useState<OutputFormat>('jpeg')
  const [rating, setRating] = useState<Rating | null>(null)
  const [imageFile, setImageFile] = useState<File | null>(null)

  const [isInitialRender, setIsInitialRender] = useState(true)

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false)
      dispatch(changeStage('idle'))
    }
  }, [])

  const imageUrl = useMemo(() => {
    if (imageFile) {
      return URL.createObjectURL(imageFile)
    }
    return null
  }, [imageFile])

  const processedImageUrl = useMemo(() => {
    const [firstAsset] = processedAssets
    return firstAsset?.url
  }, [processedAssets])

  const handleGoBack = useCallback(() => {
    dispatch(changeStage('idle'))
  }, [])

  const handleImageChange = useCallback((newFile: File) => {
    setImageFile(newFile)
    dispatch(changeStage('preview'))
  }, [])

  const handleProcessedAssetsChanged = useCallback(
    (payload: ProcessedAssetsChangedPayload) => {
      const { assets, finish, id } = payload

      dispatch(changeProcessedAssets(assets))

      if (finish) {
        dispatch(changeStage('idle'))
        navigate(`/enhancement/finish/${id}`)
      }
    },
    [],
  )

  const handleProcessingStart = useCallback(async () => {
    if (imageFile == null) return

    dispatch(changeStage('processing'))

    const strategy = new EnhanceStrategy(imageFile)
      .setHandler(handler)
      .setOutputFormat(outputFormat)

    const processor = new Processor(strategy)
    processor.on(
      ProcessorEvents.PROCESSED_ASSETS_CHANGED,
      handleProcessedAssetsChanged,
    )

    await processor.start()
  }, [imageFile, handler, outputFormat, handleProcessedAssetsChanged])

  const handleEnhanceMore = useCallback((newFile: File) => {
    setImageFile(newFile)
    setRating(null)
    dispatch(changeCurrentAssetIndex(0))
    dispatch(changeProcessedAssets([]))
    dispatch(changeStage('preview'))
  }, [])

  useEffect(() => {
    // Сброс данных, если пользователь вернулся на главный экран
    if (stage !== 'idle') return
    setImageFile(null)
    dispatch(changeCurrentAssetIndex(0))
    dispatch(changeProcessedAssets([]))
    setRating(null)
  }, [stage])

  useEffect(() => {
    // Изменение допустимых touch-action
    document.documentElement.style.setProperty('--touch-action', 'none')

    return () => {
      document.documentElement.style.setProperty('--touch-action', 'pan-y')
    }
  }, [])

  const mainClasses = useMemo(() => {
    return stage === 'idle' ? `${c.main} ${c.mainWithGradient}` : c.main
  }, [stage])

  return (
    <main className={mainClasses}>
      {stage === 'idle' && (
        <IdleStage
          handler={handler}
          handlerOptions={HANDLER_OPTIONS}
          onImageChange={handleImageChange}
        />
      )}
      {stage === 'preview' && (
        <PreviewStage
          imageUrl={imageUrl!}
          outputFormat={outputFormat}
          onOutputFormatChange={setOutputFormat}
          onProcessingStart={handleProcessingStart}
          onGoBack={handleGoBack}
        />
      )}
      {stage === 'processing' && (
        <Container flex>
          <ProcessingStage />
        </Container>
      )}
      {/* {stage === 'finish' && (
        <Container flex>
          <FinishStage
            imageUrl={imageUrl!}
            processedImageUrl={processedImageUrl!}
            rating={rating}
            onRatingChange={setRating}
            onEnhanceMore={handleEnhanceMore}
            onGoBack={handleGoBack}
          />
        </Container>
      )} */}
    </main>
  )
}

export default EnhancementPage
