import React from 'react'

import c from './AddImage.module.scss'

interface AddImageProps extends React.HTMLAttributes<HTMLInputElement> {
  text?: string
  bigger?: boolean
  imageUrl?: string | null
}

function AddImage({
  bigger = false,
  text = '',
  imageUrl = undefined,
  className,
  ...props
}: AddImageProps) {
  return (
    <div className={`${c.addImage} ${className} ${bigger ? c.bigger : ''}`}>
      <div className={c.plus} />
      {text && <span className={c.text}>{text}</span>}
      {imageUrl && (
        <div className={c.imageWrapper}>
          <img src={imageUrl} className={c.image} alt="" />
        </div>
      )}
      <label htmlFor="addImage" className={c.inputLabel}>
        <input
          type="file"
          accept="image/*"
          className="sr-only"
          id="addImage"
          {...props}
        />
      </label>
    </div>
  )
}

export default AddImage
