import React, { useMemo } from 'react'
import c from './ImageComparisonFrame.module.scss'

interface ImageComparisonFrameProps {
  imageUrl: string
  processedImageUrl: string
  sliderX: number
  scale: number
  originX: number
  originY: number
  onLoad?: () => void
  onLoadError?: () => void
}

function ImageComparisonFrame({
  imageUrl,
  processedImageUrl,
  sliderX,
  scale,
  originX,
  originY,
  onLoad = undefined,
  onLoadError = undefined,
}: ImageComparisonFrameProps) {
  const beforeClipStyles = useMemo(() => {
    return {
      clip: `rect(0px ${sliderX}px auto 0px)`,
    }
  }, [sliderX])

  const afterClipStyles = useMemo(() => {
    return {
      clip: `rect(0px auto auto ${sliderX}px)`,
    }
  }, [sliderX])

  const imageStyles = useMemo(() => {
    return {
      transform: `translate(${originX}px, ${originY}px) scale(${scale})`,
    }
  }, [scale, originX, originY])

  return (
    <div className={c.outerWrapper}>
      <div className={c.innerWrapper} style={beforeClipStyles}>
        <img
          className={c.image}
          alt=""
          src={imageUrl}
          style={imageStyles}
          onLoad={onLoad}
          onError={onLoadError}
        />
      </div>
      <div className={c.innerWrapper} style={afterClipStyles}>
        <img
          className={c.image}
          alt=""
          src={processedImageUrl}
          style={imageStyles}
          onLoad={onLoad}
          onError={onLoadError}
        />
      </div>
    </div>
  )
}

export default ImageComparisonFrame
