import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import useLocationInfo from 'hooks/useLocationInfo'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'

import GalleryIcon from 'components/icons/GalleryFilled'
import ActivityFeed from 'components/icons/ActivityFeed'
import User from 'components/icons/User'
import BackgroundRemover from 'components/icons/BackgroundRemover'

import c from './LayoutBottom.module.scss'

function LayoutBottom() {
  const { t } = useTranslation()
  const { pathname } = useLocationInfo()

  const { data: user } = useFetchRequestMyProfile()

  const isEnhancement = useMemo(
    () =>
      pathname === '/enhancement' ||
      pathname === '/face-restoration' ||
      pathname === '/background-remover',
    [pathname],
  )
  const isGeneration = useMemo(
    () => pathname === '/neuro-art' || pathname === '/magic-variations',
    [pathname],
  )

  const isFeed = useMemo(
    () => pathname === '/trending' || pathname === '/feed',
    [pathname],
  )

  return (
    <div className={c.mobileContent}>
      <nav className={c.nav}>
        <ul className={c.nav__list}>
          <li className={`${c.nav__elem} ${isFeed ? c.active : ''}`}>
            <ActivityFeed className={c.icon} />
            {t('navigation.feed')}
            <NavLink to="/feed" className={c.link} end>
              {t('navigation.feed')}
            </NavLink>
          </li>
          <li className={`${c.nav__elem} ${isEnhancement ? c.active : ''}`}>
            <GalleryIcon className={c.icon} />
            {t('navigation.AIphoto')}
            <NavLink to="/enhancement" className={c.link} end>
              {t('navigation.AIphoto')}
            </NavLink>
          </li>
          <li className={`${c.nav__elem} ${isGeneration ? c.active : ''}`}>
            <BackgroundRemover className={c.icon} />
            {t('navigation.AIart')}
            <NavLink to="/neuro-art" className={c.link} end>
              {t('navigation.AIart')}
            </NavLink>
          </li>
          <li
            className={`${c.nav__elem} ${
              pathname === `/profile/${user?.id}` ? c.active : ''
            }`}
          >
            <User className={c.icon} />
            {t('navigation.profile')}
            <NavLink to={`/profile/${user?.id}`} className={c.link} end>
              {t('navigation.profile')}
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default LayoutBottom
