import { useQuery } from '@tanstack/react-query'
import { getProfile } from 'api'
import { useAppSelector } from 'hooks/store'
import { UserResponse } from 'types/User'

interface Params {
  id: string | undefined
}

const useFetchRequestProfile = ({ id }: Params) => {
  const deviceId = useAppSelector((state) => state.settings.deviceId)
  const userId = useAppSelector((state) => state.auth.user?.uid)

  const deps = [deviceId, userId, id]

  return useQuery<UserResponse, Error>(['profile', deps], () => {
    if (id) {
      return getProfile(id!)
    }
    return Promise.reject(new Error('Invalid request ID'))
  })
}

export default useFetchRequestProfile
