import React from 'react'
import Loader from 'components/common/Loader'

import c from './CircleLoader.module.scss'

interface CircleLoaderProps {
  loadingText: string
}

function CircleLoader({ loadingText }: CircleLoaderProps) {
  return (
    <div className={c.loader}>
      <div className={c.loaderIconWrapper}>
        <Loader />
      </div>
      <div className={c.purpleShadow} />
      <div className={c.blueShadow} />
      <p className={c.loaderText}>{loadingText}</p>
    </div>
  )
}

export default CircleLoader
