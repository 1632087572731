import React from 'react'
import c from './Loader.module.scss'

function Loader() {
  return (
    <div className={c.loader}>
      <div className={c.loaderLine}>
        <div className={c.loaderCircle} />
        <div className={c.loaderCircle} />
      </div>
      <div className={c.loaderLine}>
        <div className={c.loaderCircle} />
        <div className={c.loaderCircle} />
      </div>
      <div className={c.loaderLine}>
        <div className={c.loaderCircle} />
        <div className={c.loaderCircle} />
      </div>
      <div className={c.loaderLine}>
        <div className={c.loaderCircle} />
        <div className={c.loaderCircle} />
      </div>
    </div>
  )
}

export default Loader
