import React, { useCallback, useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import Button from 'components/common/Button'
import AppleIcon from 'components/icons/AppleIcon'
import GoogleIcon from 'components/icons/GoogleIcon'
import useLogIn from 'hooks/query/auth/useLogIn'
import c from './SocialAuth.module.scss'

interface SocialAuthProps extends React.HTMLAttributes<HTMLDivElement> {
  onSuccess: () => void
}

function SocialAuth({ className, onSuccess, ...props }: SocialAuthProps) {
  const {
    mutate: logIn,
    isLoading: isLoggingIn,
    error,
  } = useLogIn({ onSuccess })

  const logInViaApple = useCallback(() => logIn({ provider: 'apple' }), [logIn])

  const logInViaGoogle = useCallback(
    () => logIn({ provider: 'google' }),
    [logIn],
  )

  const classNames = useMemo(() => {
    let result = c.socials
    if (className) result += ` ${className}`
    return result
  }, [className])

  useEffect(() => {
    if (error?.message) toast.error(error.message)
  }, [error?.message])

  return (
    <div className={classNames} {...props}>
      <Button
        className={c.socialsButton}
        startIcon={<GoogleIcon />}
        disabled={isLoggingIn}
        onClick={logInViaGoogle}
      >
        Google
      </Button>
      {/* <Button
        className={c.socialsButton}
        startIcon={<AppleIcon />}
        disabled={isLoggingIn}
        onClick={logInViaApple}
      >
        Apple
      </Button> */}
    </div>
  )
}

export default SocialAuth
