import React from 'react'
import { useTranslation } from 'react-i18next'
import { EnhancementResponse } from 'types/Response'

import AddImage from 'components/AddImage'

import c from './PhotoSelection.module.scss'

interface PhotoSelectionProps {
  generationHistoryData?: EnhancementResponse[]
  chosenHistory?: number
  imageUrl: string | null | undefined
  handleHistoryClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  onImageChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

function PhotoSelection({
  generationHistoryData = [],
  chosenHistory = undefined,
  imageUrl,
  handleHistoryClick,
  onImageChange,
}: PhotoSelectionProps) {
  const { t } = useTranslation()
  return (
    <div className={c.photoSelection}>
      <p className={c.heading}>{t('stages.variations.idle.addImage')}</p>
      <ul className={c.historyList}>
        {imageUrl ? (
          <AddImage imageUrl={imageUrl} onChange={onImageChange} />
        ) : (
          <AddImage
            text={t('stages.enhancement.idle.buttonText')}
            onChange={onImageChange}
          />
        )}
        {generationHistoryData.length > 0 &&
          generationHistoryData.map((elem) => (
            <button
              key={elem.assets[0]?.id}
              className={`${c.wrapper} ${
                chosenHistory === elem.assets[0]?.id ? c.active : ''
              }`}
              type="button"
              onClick={handleHistoryClick}
              data-id={elem.assets[0]?.id}
            >
              <img className={c.image} src={elem.assets[0].url} alt="" />
            </button>
          ))}
      </ul>
    </div>
  )
}

export default PhotoSelection
