import React from 'react'
import ImageComparisonSpace from './ImageComparisonSpace'
import c from './ImageComparison.module.scss'

interface ImageComparisonProps {
  imageUrl: string
  processedImageUrl: string
}

function ImageComparison({
  imageUrl,
  processedImageUrl,
}: ImageComparisonProps) {
  return (
    <div className={c.container}>
      <ImageComparisonSpace
        imageUrl={imageUrl}
        processedImageUrl={processedImageUrl}
      />
    </div>
  )
}

export default ImageComparison
