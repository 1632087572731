import { postRepeat, postRequestVariations } from 'api'
import { toast } from 'react-toastify'
import { BaseResponse } from 'types/Response'
import Strategy from './Strategy'

interface Constructor {
  image: File | string
  request: string
  styleTitle: string
  styleText: string
  strength: string
  requestId?: string | undefined
  negativePrompt?: string | undefined
  numSteps?: number | undefined
  seed?: string | undefined
  guidanceScale?: number | undefined
  width?: number | undefined
  height?: number | undefined
}

export default class VariationsStrategy extends Strategy {
  private readonly image: File | string

  private readonly request: string

  private readonly styleTitle: string

  private readonly styleText: string

  private readonly strength: string

  private readonly requestId?: string | undefined

  private negativePrompt?: string | undefined

  private numSteps?: number | undefined

  private seed?: string | undefined

  private guidanceScale?: number | undefined

  private width?: number | undefined

  private height?: number | undefined

  constructor({
    image,
    request,
    styleTitle,
    styleText,
    strength,
    requestId,
    guidanceScale,
    height,
    numSteps,
    negativePrompt,
    seed,
    width,
  }: Constructor) {
    super()

    this.image = image
    this.request = request
    this.styleTitle = styleTitle
    this.styleText = styleText
    this.strength = strength
    this.requestId = requestId
    this.guidanceScale = guidanceScale
    this.height = height
    this.numSteps = numSteps
    this.negativePrompt = negativePrompt
    this.seed = seed
    this.width = width
  }

  public async start(): Promise<void> {
    const { id, assets } = await this.makeRequest()
    this.collectAssets(id, assets.length).then()
  }

  public async repeat(): Promise<void> {
    const { id, assets } = await this.makeRequestRepeat()
    this.collectAssets(id, assets.length).then()
  }

  public stop(): void {
    this.collector?.stop()
  }

  public async makeRequest(): Promise<BaseResponse> {
    try {
      const formData = this.assembleFormData({
        asset: this.image,
        text: this.request,
        styleTitle: this.styleTitle,
        styleText: this.styleText,
        strength: this.strength,
        isManyAssets: 'true',
      })

      if (this.negativePrompt)
        formData.append('negativePrompt', this.negativePrompt)
      if (this.numSteps) formData.append('numSteps', String(this.numSteps))
      if (this.seed) formData.append('seed', this.seed)
      if (this.guidanceScale)
        formData.append('guidanceScale', String(this.guidanceScale))
      if (this.width) formData.append('width', String(this.width))
      if (this.height) formData.append('height', String(this.height))

      return await postRequestVariations(formData)
    } catch (error) {
      toast.error('Something went wrong, try again!')
      this.collector?.stop()
      throw error
    }
  }

  public async makeRequestRepeat(): Promise<BaseResponse> {
    try {
      return await postRepeat(this.requestId!, this.request)
    } catch (error) {
      toast.error('Something went wrong, try again!')
      this.collector?.stop()
      throw error
    }
  }
}
