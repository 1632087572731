import React, { useEffect, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import Button from 'components/common/Button'
import Loader from 'components/common/Loader'
import useInfiniteFetchRequests from 'hooks/query/useInfiniteFetchRequests'
import AddImage from 'components/AddImage'
import Handlers from 'types/Handlers'
import c from './EnhancementHistory.module.scss'

interface EnhancementHistoryProps
  extends React.HTMLAttributes<HTMLInputElement> {
  handler: `${Handlers}`
}

function EnhancementHistory({ handler, ...props }: EnhancementHistoryProps) {
  const { t } = useTranslation()

  const { ref, inView, entry } = useInView()

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteFetchRequests({
      sourceType: 'image',
      handler,
    })

  const historyItems = useMemo(() => {
    if (data === undefined) return []
    return data.pages.flatMap((p) => p)
  }, [data])

  useEffect(() => {
    if (isLoading) return
    fetchNextPage()
  }, [inView])

  return (
    <div className={c.history}>
      {isLoading && (
        <div className={c.loader}>
          <Loader />
        </div>
      )}
      {!isLoading && (
        <>
          {historyItems?.length > 0 && (
            <div className={c.grid}>
              <AddImage
                text={t('stages.enhancement.idle.buttonText')}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
              {historyItems.map((h) => (
                <div key={h.id} className={c.wrapper}>
                  <img className={c.image} src={h.assets[0].source} alt="" />
                  <NavLink
                    className={c.link}
                    to={`/enhancement/finish/${h.id}`}
                  />
                </div>
              ))}
            </div>
          )}
          {historyItems?.length < 1 && (
            <div className={c.addImageWrapper}>
              <AddImage
                bigger
                className={c.addImage}
                text={t('general.buttons.add')}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
              <h5 className={c.addImageInfo}>
                {t('stages.generation.idle.addImageInfo')}
              </h5>
            </div>
          )}
          {!isLoading && !isFetchingNextPage && hasNextPage && (
            <div ref={ref} />
          )}
          {isFetchingNextPage && (
            <div className={c.loaderWrapper}>
              <div className={c.loader}>
                <Loader />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default EnhancementHistory
