import React from 'react'
import useFetchRequestPost from 'hooks/query/useFetchRequestPost'
import { useNavigate, useParams } from 'react-router-dom'
import IconButton from 'components/common/IconButton'
import { ReactComponent as ArrowBackIcon } from '@material-design-icons/svg/round/arrow_back_ios.svg'
import Container from 'components/common/Container'
import Post from 'components/Post'
import c from './PostPage.module.scss'

function PostPage() {
  const { id } = useParams()

  const navigate = useNavigate()

  const { isLoading, error, data: postData } = useFetchRequestPost({ id })

  const onGoBack = () => {
    navigate(-1)
  }

  return (
    <div className={c.postPage}>
      <Container flex>
        <div className={c.navigation}>
          <IconButton onClick={onGoBack} className={c.goBackButton}>
            <ArrowBackIcon style={{ fill: 'currentColor' }} />
          </IconButton>
        </div>
        {postData && (
          <div className={c.content}>
            <div className={c.postWrapper}>
              <Post post={postData} imgClassName={c.postImage} />
            </div>
          </div>
        )}
      </Container>
    </div>
  )
}

export default PostPage
