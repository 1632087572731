import React from 'react'
import { Suggestion, Style } from 'types/Generation'
import { EnhancementResponse } from 'types/Response'
import Container from 'components/common/Container'
import GenerationInput from 'components/GenerationInput'
import Suggestions from 'components/Suggestions'
import GenerationStyles from 'components/GenerationStyles'
import GenerationFixedButton from 'components/GenerationFixedButton'
import PhotoSelection from 'components/PhotoSelection'
import PowerSelection from 'components/PowerSelection'
import AdvancedSettings from 'components/AdvancedSettings'

import c from './IdleStage.module.scss'

interface IdleStageProps {
  text: string
  suggestions?: Suggestion[]
  styles?: Style[]
  chosenStyle: string | undefined
  generationHistoryData?: EnhancementResponse[]
  chosenHistory?: number
  imageFile: File | null
  imageUrl: string | null | undefined
  strength: number
  setStrength: (value: any) => void
  onTextChange: (newText: string) => void
  onProcessingStart: () => void
  onExampleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  onStyleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  handleHistoryClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  onImageChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  guidanceScale: number
  setGuidanceScale: (newIndex: number) => void
  seed: string | undefined
  setSeed: (newSeed: string) => void
  steps: number
  setSteps: (newStep: number) => void
  negativePrompt: string
  setNegativePrompt: (newPrompt: string) => void
  isAdvancedSettingsOpen: boolean
  setIsAdvancedSettingsOpen: (prev: boolean) => void
  isImage: boolean
}

function IdleStage({
  text,
  suggestions = [],
  styles = [],
  chosenStyle,
  generationHistoryData = [],
  chosenHistory = undefined,
  imageUrl,
  strength,
  imageFile,
  setStrength,
  onTextChange,
  onProcessingStart,
  onExampleClick,
  onStyleClick,
  handleHistoryClick,
  onImageChange,
  guidanceScale,
  setGuidanceScale,
  seed,
  setSeed,
  steps,
  setSteps,
  negativePrompt,
  setNegativePrompt,
  isAdvancedSettingsOpen,
  setIsAdvancedSettingsOpen,
  isImage
}: IdleStageProps) {
  return (
    <Container flex>
      <div className={c.content}>
        <PhotoSelection
          generationHistoryData={generationHistoryData}
          chosenHistory={chosenHistory}
          imageUrl={imageUrl}
          handleHistoryClick={handleHistoryClick}
          onImageChange={onImageChange}
        />
        <PowerSelection setStrength={setStrength} strength={strength} />
        <GenerationInput
          text={text}
          onTextChange={onTextChange}
          onProcessingStart={onProcessingStart}
        />
        <Suggestions
          suggestions={suggestions}
          onExampleClick={onExampleClick}
        />
        <AdvancedSettings
          isAdvancedSettingsOpen={isAdvancedSettingsOpen}
          setIsAdvancedSettingsOpen={setIsAdvancedSettingsOpen}
          negativePrompt={negativePrompt}
          setNegativePrompt={setNegativePrompt}
          guidanceScale={guidanceScale}
          setGuidanceScale={setGuidanceScale}
          seed={seed}
          setSeed={setSeed}
          steps={steps}
          setSteps={setSteps}
        />
        <GenerationStyles
          chosenStyle={chosenStyle}
          styles={styles}
          onStyleClick={onStyleClick}
        />
      </div>
      <GenerationFixedButton
        text={text}
        onProcessingStart={onProcessingStart}
        isImage={isImage}
      />
    </Container>
  )
}

export default IdleStage
