import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import Modal from 'components/common/Modal'
import SocialAuth from 'components/SocialAuth'
import AuthForm from 'components/AuthForm'
import useSignUp from 'hooks/query/auth/useSignUp'
import useLogOut from 'hooks/query/auth/useLogOut'
import { putProfile } from 'api/'
import { UserRequest } from 'types/User'
import c from './UserModal.module.scss'

interface UserModalProps {
  open: boolean
  onClose: () => void
}

function UserModal({ open, onClose }: UserModalProps) {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const [nickname, setNickname] = useState<string>('')
  const [userName, setUserName] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  const onSuccess = () => {
    queryClient.refetchQueries(['myProfile'])
    onClose()
    setNickname('')
    setUserName('')
    setDescription('')
  }

  const { mutate: logOut, error } = useLogOut()

  const handleLogOut = useCallback(() => {
    logOut()
    onClose()
  }, [logOut])

  const mutation = useMutation<void, Error, UserRequest>(
    (userFields) => {
      return putProfile(userFields!)
    },
    {
      onSuccess,
    },
  )

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      mutation.mutate({ nickname, name: userName, description })
    },
    [nickname, userName, description],
  )

  useEffect(() => {
    if (mutation.error?.message) toast.error(mutation.error.message)
  }, [mutation.error?.message])

  return (
    <Modal open={open} heading={t('modals.userInfo.heading')}>
      <AuthForm
        nickname={nickname}
        userName={userName}
        description={description}
        submitText={t('modals.userInfo.submit')}
        isSubmitting={mutation.isLoading}
        onNicknameChange={setNickname}
        onUserNameChange={setUserName}
        onDescriptionChange={setDescription}
        onSubmit={handleSubmit}
        handleLogOut={handleLogOut}
      />
    </Modal>
  )
}

export default UserModal
