import React, { useCallback } from 'react'
import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check.svg'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import { setLanguage } from 'store/reducers/settingsReducer'
import c from './LanguageChange.module.scss'

function LanguageChange() {
  const dispatch = useAppDispatch()

  const language = useAppSelector((state) => state.settings.language)

  const handleLanguageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setLanguage(e.target.value))
    },
    [dispatch],
  )

  return (
    <div className={c.list}>
      <label className={c.label} htmlFor="ru">
        <input
          className="sr-only"
          type="radio"
          name="language"
          value="ru"
          id="ru"
          checked={language === 'ru'}
          onChange={handleLanguageChange}
        />
        Русский
        {language === 'ru' && <CheckIcon className={c.checkIcon} />}
      </label>
      <label className={c.label} htmlFor="en">
        <input
          className="sr-only"
          type="radio"
          name="language"
          value="en"
          id="en"
          checked={language === 'en'}
          onChange={handleLanguageChange}
        />
        English
        {language === 'en' && <CheckIcon className={c.checkIcon} />}
      </label>
    </div>
  )
}

export default LanguageChange
