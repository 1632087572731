import React from 'react'
import { useTranslation } from 'react-i18next'
import InputRange from 'react-input-range'
import resolutionSizes from 'utils/resolutionSizes'

import w from '../../images/w.png'
import h from '../../images/h.png'

import c from './ResolutionSelection.module.scss'

interface ResolutionSelectionProps {
  strength: number
  setStrength: (value: any) => void
}

function ResolutionSelection({
  strength,
  setStrength,
}: ResolutionSelectionProps) {
  const { t } = useTranslation()

  return (
    <div className={c.powerSelection}>
      <p className={c.heading}>{t('stages.generation.idle.choseResolution')}</p>
      <div className={c.rangeWrapper}>
        <InputRange
          formatLabel={(value: number) =>
            value === 192 || value === 0
              ? ''
              : `${resolutionSizes[value].width}x${resolutionSizes[value].height}`
          }
          maxValue={192}
          minValue={0}
          value={strength || 0}
          onChange={(value) => setStrength(value)}
        />
        <div className={c.maxLabel}>
          <img src={h} alt="w" />
        </div>
        <div className={c.minLabel}>
          <img src={w} alt="h" />
        </div>
      </div>
    </div>
  )
}

export default ResolutionSelection
