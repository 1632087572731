import React from 'react'

import c from './Tabs.module.scss'

interface TabsProps {
  indexOfCurrentPage: number
  className: string
  children?: React.ReactNode | any
}

function Tabs({ indexOfCurrentPage, children = [], className }: TabsProps) {
  return (
    <div className={`${c.wrapper} ${className}`}>
      {children?.length > 0 &&
        children?.map((elem: React.ReactNode) => (
          <div className={c.navigationButton} key={`${Math.random()}-tab`}>
            {elem}
          </div>
        ))}
      <div className={c.currentWrapper}>
        <div
          className={c.current}
          style={{
            left: `${(100 / children.length) * indexOfCurrentPage}%`,
            width: `${100 / children.length}%`,
          }}
        />
      </div>
    </div>
  )
}

export default Tabs
