import React from 'react'

// eslint-disable-next-line react/require-default-props
function GalleryFilled(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 48 48"
      fill="none"
      {...props}
    >
      <path d="M 12.5 5 C 8.3754991 5 5 8.3754991 5 12.5 L 5 30.5 C 5 34.624501 8.3754991 38 12.5 38 L 30.5 38 C 34.624501 38 38 34.624501 38 30.5 L 38 12.5 C 38 8.3754991 34.624501 5 30.5 5 L 12.5 5 z M 12.5 8 L 30.5 8 C 33.003499 8 35 9.9965009 35 12.5 L 35 28.152344 L 29.75 23.072266 L 29.75 23.074219 C 29.015295 22.362375 28.049695 21.998844 27.09375 22 L 27.091797 22 C 26.136566 22.000488 25.176302 22.365751 24.445312 23.072266 A 1.50015 1.50015 0 0 0 24.443359 23.072266 L 22.345703 25.101562 L 17.150391 20.074219 C 16.416911 19.365243 15.450588 19.001953 14.496094 19.001953 C 13.5416 19.001953 12.577229 19.365243 11.84375 20.074219 L 8 23.792969 L 8 12.5 C 8 9.9965009 9.9965009 8 12.5 8 z M 40 11.513672 L 40 31.5 C 40 36.187 36.187 40 31.5 40 L 11.513672 40 C 12.883672 41.818 15.053 43 17.5 43 L 31.5 43 C 37.841 43 43 37.841 43 31.5 L 43 17.5 C 43 15.054 41.818 12.883672 40 11.513672 z M 27.5 13 A 2.5 2.5 0 0 0 27.5 18 A 2.5 2.5 0 0 0 27.5 13 z M 14.496094 22.001953 C 14.7036 22.001953 14.899932 22.071443 15.064453 22.230469 L 20.189453 27.1875 L 12.150391 34.964844 C 9.8163844 34.785918 8 32.883527 8 30.5 L 8 27.966797 L 13.929688 22.230469 C 14.094207 22.071444 14.288588 22.001953 14.496094 22.001953 z M 27.09375 25 A 1.50015 1.50015 0 0 0 27.095703 25 C 27.303134 24.999644 27.501258 25.07079 27.664062 25.228516 L 34.712891 32.048828 C 34.085865 33.775109 32.455428 35 30.5 35 L 16.427734 35 L 26.529297 25.228516 L 26.529297 25.230469 C 26.693865 25.071409 26.889612 25 27.09375 25 z" />
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h18v22H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default GalleryFilled
