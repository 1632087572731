import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash.debounce'
import { ReactComponent as FocusIcon } from '@material-design-icons/svg/round/filter_center_focus.svg'
import IconButton from 'components/common/IconButton'
import useGestures from 'hooks/useGestures'
import ImageComparisonFrame from '../ImageComparisonFrame'
import ImageComparisonSlider from '../ImageComparisonSlider'
import c from './ImageComparisonSpace.module.scss'

interface ImageComparisonSpaceProps {
  imageUrl: string
  processedImageUrl: string
  onLoad?: () => void
  onLoadError?: () => void
}

function ImageComparisonSpace({
  imageUrl,
  processedImageUrl,
  onLoad = undefined,
  onLoadError = undefined,
}: ImageComparisonSpaceProps) {
  const [wrapperRect, setWrapperRect] = useState<DOMRect | undefined>(undefined)

  const wrapperRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  const handleWrapperRectChange = useCallback(
    debounce(() => {
      const rect = wrapperRef.current?.getBoundingClientRect()
      setWrapperRect(rect)
    }, 100),
    [],
  )

  useEffect(() => {
    handleWrapperRectChange()
    window.addEventListener('resize', handleWrapperRectChange)

    return () => {
      window.removeEventListener('resize', handleWrapperRectChange)
    }
  }, [])

  const {
    sliderX,
    scale,
    originX,
    originY,
    currentGesture,
    isControlsDirty,
    resetControls,
    handleWrapperPointerDown,
    handleWrapperTouchStart,
    handleWrapperWheel,
    handleSliderPointerDown,
    handleSliderTouchStart,
  } = useGestures(wrapperRect)

  const isDragging = useMemo(
    () => currentGesture === 'dragging',
    [currentGesture],
  )

  return (
    <div
      ref={wrapperRef}
      className={c.wrapper}
      onWheel={handleWrapperWheel}
      onPointerDown={handleWrapperPointerDown}
      onTouchStart={handleWrapperTouchStart}
    >
      <ImageComparisonFrame
        imageUrl={imageUrl}
        processedImageUrl={processedImageUrl}
        sliderX={sliderX}
        scale={scale}
        originX={originX}
        originY={originY}
        onLoad={onLoad}
        onLoadError={onLoadError}
      />
      <ImageComparisonSlider
        sliderX={sliderX}
        handlePointerDown={handleSliderPointerDown}
        handleTouchStart={handleSliderTouchStart}
      />
      <IconButton
        disabled={!isControlsDirty || isDragging}
        className={c.resetButton}
        onClick={resetControls}
      >
        <FocusIcon style={{ fill: 'currentColor' }} />
      </IconButton>
      <div className={`${c.badge} ${c.badgeLeft}`}>
        <p className={c.badgeText}>{t('stages.enhancement.finish.before')}</p>
      </div>
      <div className={`${c.badge} ${c.badgeRight}`}>
        <p className={c.badgeText}>{t('stages.enhancement.finish.after')}</p>
      </div>
    </div>
  )
}

export default ImageComparisonSpace
