import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/common/Button'
import TextInput from 'components/common/TextInput'
import c from './AuthForm.module.scss'

interface AuthFormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  email?: string
  password?: string
  nickname?: string
  userName?: string
  description?: string
  submitText: string
  isSubmitting: boolean
  formType?: 'logIn' | 'signUp'
  onEmailChange?: (newEmail: string) => void
  onPasswordChange?: (newPassword: string) => void
  onNicknameChange?: (newEmail: string) => void
  onUserNameChange?: (newPassword: string) => void
  onDescriptionChange?: (newPassword: string) => void
  onForgotPassword?: () => void
  onSwitch?: () => void
  handleLogOut?: () => void
}

function AuthForm({
  className,
  email = undefined,
  password = undefined,
  submitText,
  isSubmitting,
  nickname = undefined,
  userName = undefined,
  description = undefined,
  formType = undefined,
  onEmailChange = undefined,
  onPasswordChange = undefined,
  onForgotPassword = undefined,
  onNicknameChange = undefined,
  onUserNameChange = undefined,
  onDescriptionChange = undefined,
  onSwitch = undefined,
  handleLogOut = undefined,
  ...props
}: AuthFormProps) {
  const { t } = useTranslation()

  const classNames = useMemo(() => {
    let result = c.form
    if (className) result += ` ${className}`
    return result
  }, [className])

  const handleEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onEmailChange) onEmailChange(e.target.value)
    },
    [onEmailChange],
  )

  const handlePasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onPasswordChange) onPasswordChange(e.target.value)
    },
    [onPasswordChange],
  )
  const handleUserNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onUserNameChange) onUserNameChange(e.target.value)
    },
    [onUserNameChange],
  )
  const handleDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onDescriptionChange) onDescriptionChange(e.target.value)
    },
    [onDescriptionChange],
  )
  const handleNicknameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onNicknameChange) onNicknameChange(e.target.value)
    },
    [onNicknameChange],
  )

  return (
    <form className={classNames} {...props}>
      {nickname != null && (
        <TextInput
          className={c.formInput}
          required
          type="nickname"
          placeholder={t('general.formPlaceholders.nickname')}
          value={nickname}
          onChange={handleNicknameChange}
        />
      )}
      {userName != null && (
        <TextInput
          className={c.formInput}
          required
          type="name"
          placeholder={t('general.formPlaceholders.userName')}
          value={userName}
          onChange={handleUserNameChange}
        />
      )}
      {description != null && (
        <TextInput
          className={c.formInput}
          type="description"
          placeholder={t('general.formPlaceholders.description')}
          value={description}
          onChange={handleDescriptionChange}
        />
      )}
      {email != null && (
        <TextInput
          className={c.formInput}
          required
          type="email"
          placeholder={t('general.formPlaceholders.email')}
          value={email}
          onChange={handleEmailChange}
        />
      )}
      {password != null && (
        <TextInput
          className={c.formInput}
          required
          type="password"
          placeholder={t('general.formPlaceholders.password')}
          value={password}
          onChange={handlePasswordChange}
        />
      )}
      <div className={c.buttons}>
        {onForgotPassword && (
          <Button
            className={c.formForgotPassword}
            variant="text"
            onClick={onForgotPassword}
          >
            {t('modals.logIn.forgotPassword')}
          </Button>
        )}
        {formType === 'logIn' && (
          <Button
            className={c.formForgotPassword}
            variant="text"
            onClick={onSwitch}
          >
            {t('modals.signUp.heading')}
          </Button>
        )}
        {formType === 'signUp' && (
          <Button
            className={c.formForgotPassword}
            variant="text"
            onClick={onSwitch}
          >
            {t('modals.logIn.heading')}
          </Button>
        )}
        {handleLogOut && (
          <Button
            className={`${c.formForgotPassword} ${c.logOut}`}
            variant="text"
            onClick={handleLogOut}
          >
            {t('general.auth.logOut')}
          </Button>
        )}
      </div>
      <Button className={c.formSubmit} type="submit" disabled={isSubmitting}>
        {submitText}
      </Button>
    </form>
  )
}

export default AuthForm
