import i18n from 'i18n/config'
import store from 'store'
import { PostFeed } from 'types/Post'
import { auth } from 'utils/firebase'

const endpoint = process.env.REACT_APP_API_ENDPOINT || ''

export const getFeed = async (
  type: 'subscriptions' | 'trending',
  conclusion: 'video_only' | 'images_only' | 'all' = 'images_only',
  page: number = 1,
  take: number = 14,
  viewedPosts?: number[],
  headers: HeadersInit = {},
): Promise<PostFeed[]> => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = `v2/feed/${type}`

  const params = new URLSearchParams({
    offset: ((page - 1) * take).toString(),
    limit: take.toString(),
    conclusion,
  }).toString()

  let url = `${endpoint}/${domain}?${params}`
  if (type === 'trending') {
    url += `&exceptions=${viewedPosts?.toString()}`
  }

  const result = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getTrending = async (
  headers: HeadersInit = {},
): Promise<PostFeed[]> => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/feed/trending'

  const result = await fetch(`${endpoint}/${domain}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const postPost = async (
  body: { assets: number[] },
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const result = await fetch(`${endpoint}/v2/posts`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  // return result.json()
}

export const getPost = async (
  postId: string,
  headers: HeadersInit = {},
): Promise<PostFeed> => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/posts'

  const result = await fetch(`${endpoint}/${domain}/${postId}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getMyPosts = async (
  userId: string,
  page: number = 1,
  take: number = 4,
  headers: HeadersInit = {},
): Promise<PostFeed[]> => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/users'
  const path = 'posts'

  const params = new URLSearchParams({
    offset: ((page - 1) * take).toString(),
    limit: take.toString(),
  }).toString()

  const result = await fetch(
    `${endpoint}/${domain}/${userId}/${path}?${params}`,
    {
      method: 'GET',
      headers: {
        'X-Device-Id': deviceId,
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
        ...headers,
      },
    },
  )

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const likeRequest = async (
  postId: number,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/posts'

  const path = 'like'

  const result = await fetch(`${endpoint}/${domain}/${postId}/${path}`, {
    method: 'POST',
    headers: {
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  // return result.json()
}

export const unlikeRequest = async (
  postId: number,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/posts'

  const path = 'unlike'

  const result = await fetch(`${endpoint}/${domain}/${postId}/${path}`, {
    method: 'POST',
    headers: {
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  // return result.json()
}

export const sendViewedArray = async (
  postIds: number[],
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/posts'

  const path = 'views'

  const result = await fetch(`${endpoint}/${domain}/${path}`, {
    method: 'POST',
    body: JSON.stringify({ ids: postIds }),
    headers: {
      'X-Device-Id': deviceId,
      'Content-Type': 'application/json',
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  // return result.json()
}

export const deletePost = async (postId: number, headers: HeadersInit = {}) => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/posts'

  const result = await fetch(`${endpoint}/${domain}/${postId}`, {
    method: 'DELETE',
    headers: {
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}
