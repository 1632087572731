import React, { useMemo } from 'react'
import c from './ImageInput.module.scss'

interface ImageInputProps extends React.HTMLAttributes<HTMLInputElement> {
  label: string
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
}

function ImageInput({
  label,
  className,
  startIcon = undefined,
  endIcon = undefined,
  ...props
}: ImageInputProps) {
  const classNames = useMemo(() => {
    let result = c.label
    if (className) result += ` ${className}`
    if (startIcon || endIcon) result += ` ${c.labelWithIcon}`
    return result
  }, [className, startIcon, endIcon])

  return (
    <label className={classNames} htmlFor="image-input">
      {startIcon && startIcon}
      {label && <span>{label}</span>}
      {endIcon}
      <input
        className="sr-only"
        id="image-input"
        type="file"
        accept="image/*"
        {...props}
      />
    </label>
  )
}

export default ImageInput
