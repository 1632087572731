import { postRequestEnhancement } from 'api'
import { toast } from 'react-toastify'
import { OutputFormat } from 'types/OutputFormat'
import { BaseResponse } from 'types/Response'
import Handlers from 'types/Handlers'
import Strategy from './Strategy'

export default class EnhanceStrategy extends Strategy {
  private readonly image: File

  private handler: `${Handlers}` = Handlers.THOMAS_JEFFERSON

  private outputFormat: OutputFormat = 'jpeg'

  constructor(image: File) {
    super()

    this.image = image
  }

  public setHandler(handler: `${Handlers}`) {
    this.handler = handler
    return this
  }

  public setOutputFormat(outputFormat: OutputFormat) {
    this.outputFormat = outputFormat
    return this
  }

  public stop(): void {
    this.collector?.stop()
  }

  public async start(): Promise<void> {
    const { id, assets } = await this.makeRequest()

    this.collectAssets(id, assets.length).then()
  }

  public async makeRequest(): Promise<BaseResponse> {
    try {
      const formData = this.assembleFormData({
        assets: this.image,
        outputFormat: this.outputFormat,
      })

      return await postRequestEnhancement(formData, this.handler)
    } catch (error) {
      toast.error('Something went wrong, try again!')
      this.collector?.stop()
      throw error
    }
  }
}
