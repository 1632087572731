import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Processor, { VariationsStrategy } from 'api/Retomagic'
import {
  changeCurrentAssetIndex,
  changeProcessedAssets,
  changeStage,
} from 'store/reducers/stageReducer'
import useFetchAsset from 'hooks/query/useFetchAsset'
import useFetchGeneration from 'hooks/query/useFetchGeneration'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import useFetchSuggestions from 'hooks/query/useFetchSuggestions'
import useFetchStyles from 'hooks/query/useFetchStyles'

import Container from 'components/common/Container'
import IdleStage from 'components/stages/variations/IdleStage'
import ProcessingStage from 'components/stages/variations/ProcessingStage'

import {
  ProcessedAssetsChangedPayload,
  ProcessorEvents,
} from 'types/ProcessorEvents'

import resolutionSizes from 'utils/resolutionSizes'

import c from './MagicVariations.module.scss'

function MagicVariations() {
  const dispatch = useAppDispatch()
  const { stage, processedAssets, currentAssetIndex } = useAppSelector(
    (state) => state.stage,
  )

  const { assetId } = useParams()

  const navigate = useNavigate()

  const { data: generatedElement } = useFetchAsset({ assetId })

  const { data: generationHistoryData, isLoading } = useFetchGeneration()

  const { data: stylesData } = useFetchStyles()

  const { data: suggestionsData } = useFetchSuggestions()

  const [chosenStyle, setChosenStyle] = useState<string | undefined>('')

  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useState(false)
  const [text, setText] = useState<string>('')
  const [guidanceScale, setGuidanceScale] = useState<number>(7.5)
  const [seed, setSeed] = useState<string | undefined>(undefined)
  const [steps, setSteps] = useState<number>(50)
  const [negativePrompt, setNegativePrompt] = useState<string>('')

  const [strength, setStrength] = useState<number>(50)

  const [imageUrlParamAsset, setImageUrlParamAsset] = useState<
    string | undefined
  >('')

  const [generationProcessor, setGenerationProcessor] =
    useState<Processor | null>(null)

  const [imageFile, setImageFile] = useState<File | null>(null)
  // const [isItImageFile, setIsItImageFile] = useState<boolean>(false)

  const imageUrl = useMemo(() => {
    if (imageFile) {
      return URL.createObjectURL(imageFile)
    }
    return null
  }, [imageFile])

  const [chosenHistory, setChosenHistory] = useState<number | undefined>(
    undefined,
  )

  // Логика в случае прихода параметра с id ассета
  useEffect(() => {
    if (!assetId) return
    const isInHistory = generationHistoryData?.find(
      (elem) => elem.assets[0].id === Number(assetId),
    )

    if (isInHistory) {
      setChosenHistory(Number(assetId))
    } else if (generatedElement && !isInHistory) {
      setImageUrlParamAsset(generatedElement?.url)
      setChosenHistory(Number(assetId))
    }
  }, [generatedElement, assetId])

  const handleHistoryClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const thisId = Number(event.currentTarget.dataset?.id)
      setChosenHistory(thisId)
      setImageFile(null)
      setImageUrlParamAsset(undefined)
    },
    [],
  )

  // обработка события добавления изображения
  const onImageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0]
      if (file) {
        setImageFile(file)
        setChosenHistory(undefined)
      }
    },
    [],
  )

  const handleProcessedAssetsChanged = useCallback(
    (payload: ProcessedAssetsChangedPayload) => {
      const { assets, isFirstSucceededResult, id } = payload

      dispatch(changeProcessedAssets(assets))
      dispatch(changeStage('idle'))

      if (isFirstSucceededResult) {
        navigate(`/magic-variations/finish/${id}`)
      }
    },
    [],
  )

  const handleProcessingStart = useCallback(async () => {
    dispatch(changeStage('processing'))

    const thisStyle = stylesData.find((elem) => elem.id === chosenStyle)

    const strategy = new VariationsStrategy({
      image: imageFile! || chosenHistory,
      request: text,
      styleTitle: thisStyle?.title || '',
      styleText: thisStyle?.text || '',
      strength: (strength / 100).toFixed(2),
      guidanceScale,
      negativePrompt,
      numSteps: steps,
      requestId: undefined,
      seed,
    })
    const processor = new Processor(strategy)
    processor.on(
      ProcessorEvents.PROCESSED_ASSETS_CHANGED,
      handleProcessedAssetsChanged,
    )

    await processor.start()

    setGenerationProcessor(processor)
  }, [
    text,
    handleProcessedAssetsChanged,
    chosenStyle,
    stylesData,
    imageFile,
    strength,
    isAdvancedSettingsOpen,
    guidanceScale,
    seed,
    steps,
    negativePrompt,
  ])

  useEffect(() => {
    // Сброс данных, если пользователь вернулся на главный экран
    if (stage !== 'idle') return
    setText('')
    dispatch(changeProcessedAssets([]))
    dispatch(changeCurrentAssetIndex(0))
  }, [stage])

  useEffect(() => {
    // Изменение допустимых touch-action
    document.documentElement.style.setProperty('--touch-action', 'none')

    return () => {
      document.documentElement.style.setProperty('--touch-action', 'pan-y')
    }
  }, [])

  // Обработка клика по предложенным вариантам текста
  const onExampleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setText(event.currentTarget?.value)
    },
    [],
  )

  // Обработка клика по карточки стилей
  const onStyleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (chosenStyle === event.currentTarget.value) {
        setChosenStyle(undefined)
      } else {
        setChosenStyle(event.currentTarget.value)
      }
    },
    [chosenStyle],
  )

  useEffect(() => {
    return () => {
      if (stage === 'processing') {
        dispatch(changeProcessedAssets([]))
        dispatch(changeCurrentAssetIndex(0))
        setText('')
        generationProcessor?.stop()
        setGenerationProcessor(null)
        dispatch(changeStage('idle'))
      }
    }
  }, [generationProcessor])

  return (
    <div className={c.main}>
      {stage === 'idle' && (
        <IdleStage
          text={text}
          onTextChange={setText}
          onProcessingStart={handleProcessingStart}
          onExampleClick={onExampleClick}
          onStyleClick={onStyleClick}
          handleHistoryClick={handleHistoryClick}
          onImageChange={onImageChange}
          setStrength={setStrength}
          strength={strength}
          imageFile={imageFile}
          chosenStyle={chosenStyle}
          chosenHistory={chosenHistory}
          suggestions={suggestionsData}
          styles={stylesData}
          generationHistoryData={generationHistoryData}
          imageUrl={imageUrl || imageUrlParamAsset}
          guidanceScale={guidanceScale}
          setGuidanceScale={setGuidanceScale}
          seed={seed}
          setSeed={setSeed}
          steps={steps}
          setSteps={setSteps}
          isAdvancedSettingsOpen={isAdvancedSettingsOpen}
          setIsAdvancedSettingsOpen={setIsAdvancedSettingsOpen}
          negativePrompt={negativePrompt}
          setNegativePrompt={setNegativePrompt}
          isImage={!chosenHistory && !imageFile}
        />
      )}
      {stage === 'processing' && (
        <Container flex>
          <ProcessingStage />
        </Container>
      )}
    </div>
  )
}

export default MagicVariations
