import React from 'react'
import { useTranslation } from 'react-i18next'

import CircleLoader from 'components/common/CircleLoader'

import c from './ProcessingLoader.module.scss'

interface ProcessingLoaderProps {
  video?: boolean
}

function ProcessingLoader({ video = false }: ProcessingLoaderProps) {
  const { t } = useTranslation()

  return (
    <div className={c.processingStage}>
      <div className={c.loaderWrapper}>
        <CircleLoader
          loadingText={
            video
              ? t('stages.videoArt.processing.heading')
              : t('stages.generation.processing.heading')
          }
        />
      </div>
      <p className={c.bannerText}>
        {video
          ? t('stages.videoArt.processing.loaderText')
          : t('stages.enhancement.processing.bannerText')}
      </p>
    </div>
  )
}

export default ProcessingLoader
