/* eslint-disable import/prefer-default-export */
import store from 'store'
import i18n from 'i18n/config'
import { auth } from 'utils/firebase'
import { UserResponse, UserRequest } from 'types/User'

const endpoint = process.env.REACT_APP_API_ENDPOINT || ''

export const getMyProfile = async (
  headers: HeadersInit = {},
): Promise<UserResponse> => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/profile'

  const result = await fetch(`${endpoint}/${domain}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getProfile = async (
  userId: string,
  headers: HeadersInit = {},
): Promise<UserResponse> => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/users'

  const result = await fetch(`${endpoint}/${domain}/${userId}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const putProfile = async (
  body: UserRequest,
  headers: HeadersInit = {},
): Promise<void> => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const result = await fetch(`${endpoint}/v2/profile`, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }
}

export const followRequest = async (userId: string, headers: HeadersInit = {}) => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/users'

  const path = 'subscribe'

  const result = await fetch(`${endpoint}/${domain}/${userId}/${path}`, {
    method: 'POST',
    headers: {
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  // return result.json()
}

export const unFollowRequest = async (userId: string, headers: HeadersInit = {}) => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/users'

  const path = 'unsubscribe'

  const result = await fetch(`${endpoint}/${domain}/${userId}/${path}`, {
    method: 'POST',
    headers: {
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  // return result.json()
}
