import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ResolutionSelection from 'components/ResolutionSelection'
import SeedInput from 'components/SeedInput'
import GuidanceScaleSelection from 'components/GuidanceScaleSelection'
import StepsSelection from 'components/StepsSelection'
import NegativePromptInput from 'components/NegativePromptInput'

import s from './AdvancedSettings.module.scss'

interface AdvancedSettingsProps {
  resolution?: number
  setResolution?: (newIndex: number | Range) => void
  guidanceScale: number
  setGuidanceScale: (newIndex: number) => void
  seed: string | undefined
  setSeed: (newSeed: string) => void
  steps: number
  setSteps: (newStep: number) => void
  negativePrompt: string
  setNegativePrompt: (newPrompt: string) => void
  isAdvancedSettingsOpen: boolean
  setIsAdvancedSettingsOpen: (prev: boolean) => void
}

function AdvancedSettings({
  isAdvancedSettingsOpen,
  setIsAdvancedSettingsOpen,
  guidanceScale,
  setGuidanceScale,
  seed,
  setSeed,
  steps,
  setSteps,
  negativePrompt,
  setNegativePrompt,
  resolution = undefined,
  setResolution = undefined,
}: AdvancedSettingsProps) {
  const { t } = useTranslation()

  const togglePopup = () => {
    setIsAdvancedSettingsOpen(!isAdvancedSettingsOpen)
  }

  return (
    <>
      <div className={s.advancedSettingsContainer}>
        <button
          onClick={togglePopup}
          type="button"
          className={s.advancedSettings}
        >
          {t('stages.generation.idle.advancedSettings')}
        </button>
      </div>
      {isAdvancedSettingsOpen && (
        <div className={s.settings}>
          {resolution! >= 0 && setResolution && (
            <ResolutionSelection
              setStrength={setResolution}
              strength={resolution!}
            />
          )}
          <GuidanceScaleSelection
            setStrength={setGuidanceScale}
            strength={guidanceScale}
          />
          <SeedInput seed={seed} setSeed={setSeed} />
          <StepsSelection setStrength={setSteps} strength={steps} />
          <NegativePromptInput
            negativePrompt={negativePrompt}
            setNegativePrompt={setNegativePrompt}
          />
        </div>
      )}
    </>
  )
}

export default AdvancedSettings
