import React from 'react'
import { Style } from 'types/Generation'
import { useTranslation } from 'react-i18next'

import c from './GenerationStyles.module.scss'

interface GenerationStylesProps {
  styles?: Style[]
  chosenStyle: string | undefined
  onStyleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

function GenerationStyles({
  chosenStyle,
  styles = [],
  onStyleClick,
}: GenerationStylesProps) {
  const { t } = useTranslation()

  return (
    <div className={c.styles}>
      <h5 className={c.stylesTitle}>{t('stages.generation.idle.choseText')}</h5>
      <div className={c.stylesGrid}>
        {styles?.length > 0 &&
          styles?.map((elem) => (
            <button
              type="button"
              className={`${c.stylesVariant} ${
                chosenStyle === elem?.id ? c.active : ''
              }`}
              key={`styles-${elem.id}`}
              value={elem.id}
              onClick={onStyleClick}
            >
              <img src={elem.cover} className={c.picture} alt="" />
              <p className={c.text}>{elem.title}</p>
            </button>
          ))}
      </div>
    </div>
  )
}

export default GenerationStyles
