import React, { useState, useEffect } from 'react'
import { BaseAsset } from 'types/Response'

import GenerationFinish from 'components/GenerationFinish'

interface FinishStageProps {
  text?: string
  currentAssetUrl: string | undefined
  currentAssetIndex: number
  assets?: BaseAsset[]
  sharedSlides: number[]
  setSharedSlides: React.Dispatch<React.SetStateAction<number[]>>
  onGenerateMore: () => void
  onGoBack: () => void
  onTextChange: (newText: string) => void
}

function FinishStage({
  text = '',
  currentAssetUrl,
  currentAssetIndex,
  assets = [],
  sharedSlides,
  setSharedSlides,
  onGenerateMore,
  onGoBack,
  onTextChange,
}: FinishStageProps) {
  const [firstProcessedAssetIndex, setFirstProcessedAssetIndex] = useState<
    number | undefined
  >(undefined)

  useEffect(() => {
    if (firstProcessedAssetIndex) return
    const processedAsset = assets?.findIndex((a) => a.url)
    if (processedAsset) {
      setFirstProcessedAssetIndex(processedAsset)
    }
  }, [assets])

  return (
    <GenerationFinish
      currentAssetIndex={currentAssetIndex}
      onGenerateMore={onGenerateMore}
      onGoBack={onGoBack}
      sharedSlides={sharedSlides}
      assets={assets}
      onTextChange={onTextChange}
      setSharedSlides={setSharedSlides}
      text={text}
    />
  )
}

export default FinishStage
