import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ShareIcon } from '@material-design-icons/svg/filled/share.svg'
import Button from 'components/common/Button'
import useShare from 'hooks/useShare'

interface ShareButtonProps {
  imageUrl: string
  className?: string
}

function ShareButton({ imageUrl, className = undefined }: ShareButtonProps) {
  const { t } = useTranslation()

  const { handleShare } = useShare(imageUrl)

  return (
    <Button
      className={className}
      startIcon={<ShareIcon style={{ fill: 'currentColor' }} />}
      onClick={handleShare}
    >
      {t('general.buttons.share')}
    </Button>
  )
}

export default ShareButton
