import React from 'react'
import { ReactComponent as DropDownIcon } from '@material-design-icons/svg/filled/arrow_drop_down.svg'
import { NavLink } from 'react-router-dom'
import c from './DropDown.module.scss'

interface Link {
  id: number
  title: string
  description: string
  link: string
}

interface DropDownProps {
  links: Link[]
  pathname: string
  title: string
  colored?: boolean
}

function DropDown({ links, pathname, title, colored = false }: DropDownProps) {
  const pathnameUrl = pathname || '/'

  return (
    <div className={c.dropdownWrapper}>
      <div className={`${c.dropdown} ${colored ? c.active : ''}`}>
        {title} <DropDownIcon className={c.icon} style={{ fill: 'white' }} />
      </div>
      <ul className={c.menu}>
        <div className={c.header}>
          {title} <DropDownIcon className={c.icon} style={{ fill: 'white' }} />
        </div>
        <div className={c.linksWrapper}>
          <div className={c.links}>
            {links.length > 0 &&
              links.map((elem) => (
                <NavLink
                  to={elem.link}
                  className={`${c.link} ${
                    elem.link === pathnameUrl ? c.active : ''
                  }`}
                  key={elem.id}
                >
                  <span className={c.linkTitle}>{elem.title}</span>
                  <span className={c.linkDescription}>{elem.description}</span>
                </NavLink>
              ))}
          </div>
        </div>
      </ul>
    </div>
  )
}

export default DropDown
