/* eslint-disable import/prefer-default-export */
import store from 'store'
import i18n from 'i18n/config'
import { auth } from 'utils/firebase'

const endpoint = process.env.REACT_APP_API_ENDPOINT || ''

export const adminDeleteEverywhere = async (
  postId: number,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/admin/post'

  const result = await fetch(`${endpoint}/${domain}/${postId}`, {
    method: 'DELETE',
    headers: {
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  // return result.json()
}

export const adminHideTrends = async (
  postId: number,
  headers: HeadersInit = {},
) => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/admin/post'

  const result = await fetch(`${endpoint}/${domain}/${postId}/lock`, {
    method: 'POST',
    headers: {
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  // return result.json()
}
