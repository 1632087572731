import i18n from 'i18n/config'
import store from 'store'
import Handlers from 'types/Handlers'
import { BaseAsset, BaseResponse, EnhancementResponse } from 'types/Response'
import { RequestGeneration } from 'types/FormDataObject'
import { auth } from 'utils/firebase'
import { Style, Suggestion } from 'types/Generation'

const endpoint = process.env.REACT_APP_API_ENDPOINT || ''

export const postRequestEnhancement = async (
  formData: FormData,
  handler: `${Handlers}`,
  headers: HeadersInit = {},
): Promise<BaseResponse> => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const result = await fetch(
    `${endpoint}/v2/requests/${handler.toLowerCase()}`,
    {
      method: 'POST',
      body: formData,
      headers: {
        'X-Device-Id': deviceId,
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
        ...headers,
      },
    },
  )

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const postRequestNeuroArt = async (
  dataObject: RequestGeneration,
  headers: HeadersInit = {},
): Promise<BaseResponse> => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const result = await fetch(`${endpoint}/v2/requests/john_adams`, {
    method: 'POST',
    body: JSON.stringify(dataObject),
    headers: {
      'Content-Type': 'application/json',
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const postRequestVariations = async (
  formData: FormData,
  headers: HeadersInit = {},
): Promise<BaseResponse> => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const result = await fetch(`${endpoint}/v2/requests/james_madison`, {
    method: 'POST',
    body: formData,
    headers: {
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const postRepeat = async (
  requestId: string,
  text: string,
  headers: HeadersInit = {},
): Promise<BaseResponse> => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const result = await fetch(`${endpoint}/v2/requests/${requestId}/repeat`, {
    method: 'POST',
    body: JSON.stringify({ text }),
    headers: {
      'Content-Type': 'application/json',
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const postRequestVideoArt = async (
  dataObject: RequestGeneration,
  headers: HeadersInit = {},
): Promise<BaseResponse> => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const result = await fetch(`${endpoint}/v2/requests/john_quincy_adams`, {
    method: 'POST',
    body: JSON.stringify(dataObject),
    headers: {
      'Content-Type': 'application/json',
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getRequestById = async (
  requestId: number,
  headers: HeadersInit = {},
): Promise<BaseResponse> => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const result = await fetch(`${endpoint}/v2/requests/${requestId}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getAssetById = async (
  assetId: number,
  headers: HeadersInit = {},
): Promise<BaseAsset> => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const result = await fetch(`${endpoint}/v2/assets/${assetId}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getRequests = async (
  sourceType: 'image' | 'text',
  handler: `${Handlers}`,
  page: number = 1,
  take: number = 14,
  headers: HeadersInit = {},
): Promise<EnhancementResponse[]> => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const domain = 'v2/requests'

  const params = new URLSearchParams({
    source_type: sourceType,
    offset: ((page - 1) * take).toString(),
    limit: take.toString(),
    handler,
  }).toString()

  const result = await fetch(`${endpoint}/${domain}?${params}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getGeneration = async (
  headers: HeadersInit = {},
): Promise<EnhancementResponse[]> => {
  const {
    settings: { deviceId },
  } = store.getState()

  const token = await auth.currentUser?.getIdToken()

  const params = new URLSearchParams({
    source_type: 'text',
    limit: '4',
    handler: 'John_Adams',
  }).toString()

  const domain = 'v2/requests'

  const result = await fetch(`${endpoint}/${domain}?${params}`, {
    method: 'GET',
    headers: {
      'X-Device-Id': deviceId,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}
export const getStyles = async (
  headers: HeadersInit = {},
): Promise<Style[]> => {
  const result = await fetch('https://data.retomagic.com/styles', {
    method: 'GET',
    headers: {
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}

export const getSuggestions = async (
  headers: HeadersInit = {},
): Promise<Suggestion[]> => {
  const result = await fetch('https://data.retomagic.com/suggestions', {
    method: 'GET',
    headers: {
      ...headers,
    },
  })

  if (!result.ok) {
    throw new Error(`${i18n.t('error.api')}: ${result.status}`)
  }

  return result.json()
}
