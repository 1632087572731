import React from 'react'

interface DocumentPageProps {
  document: React.ReactNode
}

function DocumentPage({ document }: DocumentPageProps) {
  return <div>{document}</div>
}

export default DocumentPage
