export default [
  { width: 768, height: 1536 },
  { width: 776, height: 1536 },
  { width: 784, height: 1536 },
  { width: 792, height: 1536 },
  { width: 800, height: 1536 },
  { width: 808, height: 1536 },
  { width: 816, height: 1536 },
  { width: 824, height: 1536 },
  { width: 832, height: 1536 },
  { width: 840, height: 1536 },
  { width: 848, height: 1536 },
  { width: 856, height: 1536 },
  { width: 864, height: 1536 },
  { width: 872, height: 1536 },
  { width: 880, height: 1536 },
  { width: 888, height: 1536 },
  { width: 896, height: 1536 },
  { width: 904, height: 1536 },
  { width: 912, height: 1536 },
  { width: 920, height: 1536 },
  { width: 928, height: 1536 },
  { width: 936, height: 1536 },
  { width: 944, height: 1536 },
  { width: 952, height: 1536 },
  { width: 960, height: 1536 },
  { width: 968, height: 1536 },
  { width: 976, height: 1536 },
  { width: 984, height: 1536 },
  { width: 992, height: 1536 },
  { width: 1000, height: 1536 },
  { width: 1008, height: 1536 },
  { width: 1016, height: 1536 },
  { width: 1024, height: 1536 },
  { width: 1032, height: 1536 },
  { width: 1040, height: 1536 },
  { width: 1048, height: 1536 },
  { width: 1056, height: 1536 },
  { width: 1064, height: 1536 },
  { width: 1072, height: 1536 },
  { width: 1080, height: 1536 },
  { width: 1088, height: 1536 },
  { width: 1096, height: 1536 },
  { width: 1104, height: 1536 },
  { width: 1112, height: 1536 },
  { width: 1120, height: 1536 },
  { width: 1128, height: 1536 },
  { width: 1136, height: 1536 },
  { width: 1144, height: 1536 },
  { width: 1152, height: 1536 },
  { width: 1160, height: 1536 },
  { width: 1168, height: 1536 },
  { width: 1176, height: 1536 },
  { width: 1184, height: 1536 },
  { width: 1192, height: 1536 },
  { width: 1200, height: 1536 },
  { width: 1208, height: 1536 },
  { width: 1216, height: 1536 },
  { width: 1224, height: 1536 },
  { width: 1232, height: 1536 },
  { width: 1240, height: 1536 },
  { width: 1248, height: 1536 },
  { width: 1256, height: 1536 },
  { width: 1264, height: 1536 },
  { width: 1272, height: 1536 },
  { width: 1280, height: 1536 },
  { width: 1288, height: 1536 },
  { width: 1296, height: 1536 },
  { width: 1304, height: 1536 },
  { width: 1312, height: 1536 },
  { width: 1320, height: 1536 },
  { width: 1328, height: 1536 },
  { width: 1336, height: 1536 },
  { width: 1344, height: 1536 },
  { width: 1352, height: 1536 },
  { width: 1360, height: 1536 },
  { width: 1368, height: 1536 },
  { width: 1376, height: 1536 },
  { width: 1384, height: 1536 },
  { width: 1392, height: 1536 },
  { width: 1400, height: 1536 },
  { width: 1408, height: 1536 },
  { width: 1416, height: 1536 },
  { width: 1424, height: 1536 },
  { width: 1432, height: 1536 },
  { width: 1440, height: 1536 },
  { width: 1448, height: 1536 },
  { width: 1456, height: 1536 },
  { width: 1464, height: 1536 },
  { width: 1472, height: 1536 },
  { width: 1480, height: 1536 },
  { width: 1488, height: 1536 },
  { width: 1496, height: 1536 },
  { width: 1504, height: 1536 },
  { width: 1512, height: 1536 },
  { width: 1520, height: 1536 },
  { width: 1528, height: 1536 },
  { width: 1536, height: 1536 },
  { width: 1536, height: 1528 },
  { width: 1536, height: 1520 },
  { width: 1536, height: 1512 },
  { width: 1536, height: 1504 },
  { width: 1536, height: 1496 },
  { width: 1536, height: 1488 },
  { width: 1536, height: 1480 },
  { width: 1536, height: 1472 },
  { width: 1536, height: 1464 },
  { width: 1536, height: 1456 },
  { width: 1536, height: 1448 },
  { width: 1536, height: 1440 },
  { width: 1536, height: 1432 },
  { width: 1536, height: 1424 },
  { width: 1536, height: 1416 },
  { width: 1536, height: 1408 },
  { width: 1536, height: 1400 },
  { width: 1536, height: 1392 },
  { width: 1536, height: 1384 },
  { width: 1536, height: 1376 },
  { width: 1536, height: 1368 },
  { width: 1536, height: 1360 },
  { width: 1536, height: 1352 },
  { width: 1536, height: 1344 },
  { width: 1536, height: 1336 },
  { width: 1536, height: 1328 },
  { width: 1536, height: 1320 },
  { width: 1536, height: 1312 },
  { width: 1536, height: 1304 },
  { width: 1536, height: 1296 },
  { width: 1536, height: 1288 },
  { width: 1536, height: 1280 },
  { width: 1536, height: 1272 },
  { width: 1536, height: 1264 },
  { width: 1536, height: 1256 },
  { width: 1536, height: 1248 },
  { width: 1536, height: 1240 },
  { width: 1536, height: 1232 },
  { width: 1536, height: 1224 },
  { width: 1536, height: 1216 },
  { width: 1536, height: 1208 },
  { width: 1536, height: 1200 },
  { width: 1536, height: 1192 },
  { width: 1536, height: 1184 },
  { width: 1536, height: 1176 },
  { width: 1536, height: 1168 },
  { width: 1536, height: 1160 },
  { width: 1536, height: 1152 },
  { width: 1536, height: 1144 },
  { width: 1536, height: 1136 },
  { width: 1536, height: 1128 },
  { width: 1536, height: 1120 },
  { width: 1536, height: 1112 },
  { width: 1536, height: 1104 },
  { width: 1536, height: 1096 },
  { width: 1536, height: 1088 },
  { width: 1536, height: 1080 },
  { width: 1536, height: 1072 },
  { width: 1536, height: 1064 },
  { width: 1536, height: 1056 },
  { width: 1536, height: 1048 },
  { width: 1536, height: 1040 },
  { width: 1536, height: 1032 },
  { width: 1536, height: 1024 },
  { width: 1536, height: 1016 },
  { width: 1536, height: 1008 },
  { width: 1536, height: 1000 },
  { width: 1536, height: 992 },
  { width: 1536, height: 984 },
  { width: 1536, height: 976 },
  { width: 1536, height: 968 },
  { width: 1536, height: 960 },
  { width: 1536, height: 952 },
  { width: 1536, height: 944 },
  { width: 1536, height: 936 },
  { width: 1536, height: 928 },
  { width: 1536, height: 920 },
  { width: 1536, height: 912 },
  { width: 1536, height: 904 },
  { width: 1536, height: 896 },
  { width: 1536, height: 888 },
  { width: 1536, height: 880 },
  { width: 1536, height: 872 },
  { width: 1536, height: 864 },
  { width: 1536, height: 856 },
  { width: 1536, height: 848 },
  { width: 1536, height: 840 },
  { width: 1536, height: 832 },
  { width: 1536, height: 824 },
  { width: 1536, height: 816 },
  { width: 1536, height: 808 },
  { width: 1536, height: 800 },
  { width: 1536, height: 792 },
  { width: 1536, height: 784 },
  { width: 1536, height: 776 },
  { width: 1536, height: 768 },
]
