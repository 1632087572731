import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface InitialState {
  signUpModalOpen: boolean
  logInModalOpen: boolean
  promoModalOpen: boolean
  userModalOpen: boolean
}

const initialState: InitialState = {
  signUpModalOpen: false,
  logInModalOpen: false,
  promoModalOpen: false,
  userModalOpen: false,
}

const turnEveryModalClose = (state: InitialState) => {
  state.signUpModalOpen = false
  state.logInModalOpen = false
  state.promoModalOpen = false
  state.userModalOpen = false
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setLogInModalOpen: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        turnEveryModalClose(state)
        state.logInModalOpen = true
      } else {
        state.logInModalOpen = false
      }
    },
    setSignUpModalOpen: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        turnEveryModalClose(state)
        state.signUpModalOpen = true
      } else {
        state.signUpModalOpen = false
      }
    },
    setPromoModalOpen: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        turnEveryModalClose(state)
        state.promoModalOpen = true
      } else {
        state.promoModalOpen = false
      }
    },
    setUserModalOpen: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        turnEveryModalClose(state)
        state.userModalOpen = true
      } else {
        state.userModalOpen = false
      }
    },
  },
})

export default modalSlice.reducer

export const {
  setLogInModalOpen,
  setSignUpModalOpen,
  setPromoModalOpen,
  setUserModalOpen,
} = modalSlice.actions
