enum Handlers {
  GEORGE_WASHINGTON = 'George_Washington',
  THOMAS_JEFFERSON = 'Thomas_Jefferson',
  JAMES_MADISON = 'James_Madison',
  JAMES_MONROE = 'James_Monroe',
  JOHN_ADAMS = 'John_Adams',
  JOHN_QUINCY_ADAMS = 'John_Quincy_Adams',
}

export interface HandlerOption {
  value: `${Handlers}`
  label: string
}

export default Handlers
