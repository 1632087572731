import React from 'react'
import { useTranslation } from 'react-i18next'
import InputRange from 'react-input-range'

import c from './StepsSelection.module.scss'

interface StepsSelectionProps {
  strength: number | undefined
  setStrength: (value: any) => void
}

function StepsSelection({ strength, setStrength }: StepsSelectionProps) {
  const { t } = useTranslation()

  return (
    <div className={c.powerSelection}>
      <p className={c.heading}>{t('stages.generation.idle.choseStepCount')}</p>
      <div className={c.rangeWrapper}>
        <InputRange
          maxValue={100}
          minValue={10}
          value={strength || 10}
          onChange={(value) => setStrength(value)}
        />
      </div>
    </div>
  )
}

export default StepsSelection
