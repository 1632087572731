import React, { useCallback, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import EnhancementHistory from 'components/EnhancementHistory'
import HandlerSelect from 'components/HandlerSelect'
import ImageInput from 'components/ImageInput'
import useLocationInfo from 'hooks/useLocationInfo'
import Handlers, { HandlerOption } from 'types/Handlers'
import Tabs from 'components/Tabs'
import Container from 'components/common/Container'

import { ReactComponent as DownloadIcon } from '@material-design-icons/svg/outlined/file_download.svg'

import c from './IdleStage.module.scss'

interface IdleStageProps {
  handler: `${Handlers}`
  handlerOptions: HandlerOption[]
  onImageChange: (newFile: File) => void
}

function IdleStage({ handler, handlerOptions, onImageChange }: IdleStageProps) {
  const { t } = useTranslation()

  const { pathname } = useLocationInfo()

  const indexOfCurrentPage = useMemo(() => {
    switch (pathname) {
      case '/enhancement':
        return 0
      case '/face-restoration':
        return 1
      case '/background-remover':
        return 2

      default:
        return 0
    }
  }, [pathname])

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0]
      if (file) onImageChange(file)
    },
    [onImageChange],
  )

  return (
    <>
      <div className={c.topPage}>
        <div className={c.tabsWrapper}>
          <Tabs indexOfCurrentPage={indexOfCurrentPage} className={c.tabs}>
            <div className={c.tabLink}>
              {t('navigation.enhancement')}
              <NavLink className={c.linkFill} to="/enhancement" end>
                {t('navigation.enhancement')}
              </NavLink>
            </div>
            <div className={c.tabLink}>
              {t('navigation.restoration')}
              <NavLink className={c.linkFill} to="/face-restoration">
                {t('navigation.restoration')}
              </NavLink>
            </div>
            <div className={c.tabLink}>
              {t('navigation.remover')}
              <NavLink className={c.linkFill} to="/background-remover">
                {t('navigation.remover')}
              </NavLink>
            </div>
          </Tabs>
        </div>
        <ImageInput
          className={c.imageInput}
          label={t('stages.enhancement.idle.buttonText')}
          startIcon={<DownloadIcon style={{ fill: 'currentColor' }} />}
          onChange={handleInputChange}
        />
      </div>
      <Container flex>
        <EnhancementHistory onChange={handleInputChange} handler={handler} />
      </Container>
    </>
  )
}

export default IdleStage
