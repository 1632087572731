import React, { useCallback, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Container from 'components/common/Container'
import FinishStage from 'components/stages/enhancement/FinishStage'
import Loader from 'components/common/Loader'
import {
  changeCurrentAssetIndex,
  changeProcessedAssets,
} from 'store/reducers/stageReducer'

import { useAppDispatch } from 'hooks/store'
import useFetchRequest from 'hooks/query/useFetchRequest'
import { Rating } from 'types/Rating'

import c from './EnhancementFinishPage.module.scss'

function EnhancementFinishPage() {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { assetId } = useParams()

  const [rating, setRating] = useState<Rating | null>(null)

  const { data, isLoading, isSuccess, isError, error } = useFetchRequest({
    requestId: assetId,
  })

  const handleGoBack = useCallback(() => {
    //     // Сброс данных, если пользователь вернулся на главный экран
    dispatch(changeProcessedAssets([]))
    dispatch(changeCurrentAssetIndex(0))
    setRating(null)
    navigate(-1)
  }, [])

  const imageUrl = data?.assets?.[0]?.source
  const processedImageUrl = data?.assets?.[0]?.url

  return (
    <main className={c.main}>
      <Container flex fluid>
        {isLoading && (
          <div className={c.loader}>
            <Loader />
          </div>
        )}
        {isSuccess && imageUrl && processedImageUrl && (
          <FinishStage
            imageUrl={imageUrl}
            processedImageUrl={processedImageUrl}
            rating={rating}
            onRatingChange={setRating}
            onGoBack={handleGoBack}
          />
        )}
        {isError && (
          <>
            <p className={c.errorMessage}>{error?.message ?? ''}</p>
            <NavLink className={c.errorLink} to="/">
              {t('general.buttons.backToMainPage')}
            </NavLink>
          </>
        )}
      </Container>
    </main>
  )
}

export default EnhancementFinishPage
