import React, { useEffect } from 'react'
import { onAuthStateChanged } from 'firebase/auth'

import { setUser, initialize } from 'store/reducers/authReducer'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import { auth } from 'utils/firebase'
import convertUserToUserInfo from 'utils/convertUserToUserInfo'

function Authorizer() {
  const { isAuthInitialized } = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()

  useEffect(() => {
    return onAuthStateChanged(auth, (currentUser) => {
      const userInfo = convertUserToUserInfo(currentUser)
      dispatch(setUser(userInfo))
      if (isAuthInitialized) return
      dispatch(initialize())
    })
  }, [])

  return null
}

export default Authorizer
