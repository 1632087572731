import { useQuery } from '@tanstack/react-query'
import { getMyProfile } from 'api'
import { useAppSelector } from 'hooks/store'
import { UserResponse } from 'types/User'

const useFetchRequestMyProfile = () => {
  const deviceId = useAppSelector((state) => state.settings.deviceId)
  const userId = useAppSelector((state) => state.auth.user?.uid)
  const { isAuthInitialized } = useAppSelector((state) => state.auth)

  const deps = [deviceId, userId]

  return useQuery<UserResponse | null, Error>(
    ['myProfile', deps],
    () => {
      if (userId) {
        return getMyProfile()
      }
      return Promise.resolve(null)
    },
    { enabled: isAuthInitialized },
  )
}

export default useFetchRequestMyProfile
