import Handlers from 'types/Handlers'
import { toast } from 'react-toastify'
import { BaseResponse } from 'types/Response'
import { RequestGeneration } from 'types/FormDataObject'
import { postRequestVideoArt } from 'api/neuro-technologies'

import Strategy from './Strategy'

export default class VideoStrategy extends Strategy {
  private readonly requestId?: string

  private readonly text: string

  private handler: `${Handlers}` = Handlers.JOHN_QUINCY_ADAMS

  constructor(request: string, requestId?: string) {
    super()
    this.text = request
    this.requestId = requestId
  }

  public stop(): void {
    this.collector?.stop()
  }

  public async start(): Promise<void> {
    const { id, assets } = await this.makeRequest()

    this.collectAssets(id, assets.length).then()
  }

  public async makeRequest(): Promise<BaseResponse> {
    try {
      const requestData: RequestGeneration = {
        text: this.text,
        isManyAssets: true,
      }

      return await postRequestVideoArt(requestData)
    } catch (error) {
      toast.error('Something went wrong, try again!')
      this.collector?.stop()
      throw error
    }
  }
}
