import { useInfiniteQuery } from '@tanstack/react-query'
import { getRequests } from 'api'
import { useAppSelector } from 'hooks/store'
import Handlers from 'types/Handlers'
import { EnhancementResponse } from 'types/Response'

const TAKE = 14

interface Params {
  sourceType: 'image' | 'text'
  handler: `${Handlers}`
}

const useInfiniteFetchRequests = ({ sourceType, handler }: Params) => {
  const deviceId = useAppSelector((state) => state.settings.deviceId)
  const userId = useAppSelector((state) => state.auth.user?.uid)

  const deps = [deviceId, userId, sourceType]

  return useInfiniteQuery<EnhancementResponse[], Error>(
    [`infinite-requests-${handler}`, deps],
    async ({ pageParam: page = 1 }) => {
      return getRequests(sourceType, handler, page, TAKE)
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < TAKE) return undefined
        return allPages.length + 1
      },
    },
  )
}

export default useInfiniteFetchRequests
