import React, { useEffect, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import useLocationInfo from 'hooks/useLocationInfo'
import useLogOut from 'hooks/query/auth/useLogOut'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import { useAppSelector } from 'hooks/store'
import useModal from 'hooks/useModal'
import {
  setLogInModalOpen,
  setSignUpModalOpen,
} from 'store/reducers/modalReducer'

import Button from 'components/common/Button'
import DropDown from 'components/DropDown'
import Loader from 'components/common/Loader'
import logo from 'images/logo.png'

import c from './LayoutHeader.module.scss'

function DesktopLayoutHeader() {
  const { t } = useTranslation()

  const { logInModalOpen, signUpModalOpen } = useAppSelector(
    (state) => state.modal,
  )

  const { open: openLogInModal } = useModal(logInModalOpen, setLogInModalOpen)
  const { open: openSignUpModal } = useModal(
    signUpModalOpen,
    setSignUpModalOpen,
  )

  const { isLoading: isUserLoading, data: user } = useFetchRequestMyProfile()

  const { pathname } = useLocationInfo()

  const { error } = useLogOut()

  useEffect(() => {
    if (error?.message) toast.error(error.message)
  }, [error?.message])

  const enhancementDropDown = useMemo(
    () => [
      {
        id: 0,
        title: t('navigation.enhancement'),
        description: t('navigation.info.enhancement'),
        link: '/enhancement',
      },
      {
        id: 1,
        title: t('navigation.restoration'),
        description: t('navigation.info.faceRestoration'),
        link: '/face-restoration',
      },
      {
        id: 2,
        title: t('navigation.remover'),
        description: t('navigation.info.removeBackground'),
        link: '/background-remover',
      },
    ],
    [t],
  )

  const generationDropDown = useMemo(
    () => [
      {
        id: 0,
        title: t('navigation.generation'),
        description: t('navigation.info.neuroArt'),
        link: '/neuro-art',
      },
      {
        id: 1,
        title: t('navigation.magicVariations'),
        description: t('navigation.info.magicVariations'),
        link: '/magic-variations',
      },
      {
        id: 2,
        title: t('navigation.videoArt'),
        description: t('navigation.info.videoArt'),
        link: '/video-art',
      },
    ],
    [t],
  )

  const headerContent = useMemo(() => {
    return (
      <div className={c.content}>
        <div className={c.leftPart}>
          <div className={c.logo}>
            <img className={c.logoImage} alt="Logo" src={logo} />
            <p className={c.logoText}>Retomagic</p>
            <NavLink className={c.link} to="/feed" end>
              {t('navigation.best')}
            </NavLink>
          </div>
          <nav className={c.nav}>
            <ul className={c.nav__list}>
              <li className={c.nav__elem}>
                <NavLink to="/trending" end>
                  {t('navigation.best')}
                </NavLink>
              </li>
              <li className={c.nav__elem}>
                <NavLink to="/feed" end>
                  {t('navigation.feed')}
                </NavLink>
              </li>
              <li className={c.nav__elem}>
                <NavLink to="/neuro-art" end>
                  {t('navigation.generationSpace')}
                </NavLink>
              </li>
              <li className={c.nav__elem}>
                <NavLink to="/magic-variations" end>
                  {t('navigation.magicVariations')}
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        <div className={c.rightPart}>
          <div className={c.dropdowns}>
            <DropDown
              links={enhancementDropDown}
              pathname={pathname}
              title={t('general.buttons.enhance')}
            />
            <DropDown
              links={generationDropDown}
              pathname={pathname}
              title={t('general.buttons.generate')}
              colored
            />
          </div>
          {!isUserLoading && (
            <>
              {user && (
                <div className={c.user}>
                  <p className={c.nickname}>{user.nickname}</p>
                  <div className={c.avatarWrapper}>
                    {user.avatar && (
                      <img
                        className={c.avatar}
                        src={user.avatar}
                        alt="avatar"
                      />
                    )}
                    <p className={c.avatarCover} />
                  </div>
                  <NavLink className={c.link} to={`/profile/${user.id}`} end />
                </div>
              )}
              {!user && (
                <div>
                  <Button
                    className={c.authButton}
                    variant="text"
                    onClick={openLogInModal}
                  >
                    {t('general.auth.logIn')}
                  </Button>
                </div>
              )}
            </>
          )}
          {isUserLoading && (
            <div className={c.loader}>
              <Loader />
            </div>
          )}
        </div>
      </div>
    )
  }, [
    t,
    user,
    pathname,
    isUserLoading,
    enhancementDropDown,
    generationDropDown,
    openLogInModal,
    openSignUpModal,
  ])

  return (
    <header className={c.header}>
      <div className={c.container}>{headerContent}</div>
    </header>
  )
}

export default DesktopLayoutHeader
